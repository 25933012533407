import { GRID_TREE_DATA_GROUPING_FIELD, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import {
  compactGridHeaderClassName,
  computedValues,
  depths,
  foreignHash,
  isDeliveryCandidate,
  multiQc,
  pipeline,
  referenceType,
  sampleBbid,
  sampleId,
  sampleIdentifier,
  sequenceId,
  sequenceRunId,
} from '../../util/Constants';
import { getSampleIdentifier, truncateForeignHash } from '../../data/SampleData';
import {
  GetInformaticsResultDetails,
  GetInformaticsResultDetailsResearchProjectId,
  GetSampleJourneyTransitionInformation,
  SampleJourneyInformaticsResultDetails,
  SampleJourneyTransitionInformation,
} from '../../data/SampleJourneyData';
import { find, flatMap, keyBy, mapValues, merge } from 'lodash';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Dictionary } from '../../util/TypeUtil';
import useInformaticsReferences from '../../components/hooks/UseInformaticsReferences';
import useAuth from '../../auth/UseAuth';
import { LoadingProps, LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { InformaticsPipelineResult } from '../../data/InformaticsQualityCheckData';
import { InformaticsPipeline, InformaticsReference } from '../../data/InformaticsData';
import { renderCellMultiQcDownloadButton } from '../../components/grid/GridCellMultiQcDownloadButton';
import { renderGridCellInformaticsComputedValues } from '../../components/grid/GridCellInformaticsComputedValues';
import { renderGridCellInformaticsSequenceDepths } from '../../components/grid/GridCellInformaticsSequenceDepths';
import { FlexTableBox } from '../../components/FlexTableBox';
import { Box } from '@mui/material';
import { DecryptForeignHashesSwitch } from '../../components/DecryptForeignHashesSwitch';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { renderSampleHierarchyGridCell } from '../../components/grid/cell/SampleHierarchyGridCell';
import { CustomizableSwitch } from '../../components/CustomizableSwitch';
import { SampleTrackingGridWrapper } from '../../components/grid/SampleTrackingGridWrapper';
import useInformaticsPipelines from '../../components/hooks/UseInformaticsPipelines';

export type Row = InformaticsPipelineResult &
  SampleJourneyTransitionInformation & {
    computedValues?: Dictionary<object>;
    sampleIdentifier: string;
    id: number;
    reference?: InformaticsReference;
    sequenceRunId?: string;
  };

export const SampleJourneyInformaticsGrid = ({ researchProjectId }: { researchProjectId: string }) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'Loading' });
  const [transitionInformation, setTransitionInformation] = useState<ReadonlyArray<SampleJourneyTransitionInformation>>(
    []
  );
  const [identifiersBySampleId, setIdentifiersBySampleId] = useState<Dictionary<string>>({});
  const [useDecryptedHashes, setUseDecryptedHashes] = useState<boolean>(false);
  const [details, setDetails] = useState<ReadonlyArray<SampleJourneyInformaticsResultDetails>>([]);
  const [groupBySample, setGroupBySample] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const details = await GetInformaticsResultDetailsResearchProjectId(researchProjectId, accessToken);

      const allSampleIds = details.map(i => i.sampleId);
      const informationData = await GetSampleJourneyTransitionInformation(
        allSampleIds,
        useDecryptedHashes,
        accessToken
      );

      const allSamples = flatMap(informationData, i => i.sample);
      const sampleById = keyBy(allSamples, i => i.sampleId);
      const informationById = keyBy(informationData, i => i.sample.sampleId);
      const identifiers = mapValues(
        keyBy(allSampleIds, i => i),
        sampleId => {
          const info = informationById[sampleId];
          const sample = sampleById[sampleId];

          return getSampleIdentifier(
            sample,
            info?.currentLabAssignedSampleLabel ?? info?.labAssignedSampleLabelCreatedIn
          );
        }
      );

      setDetails(details);
      setIdentifiersBySampleId(identifiers);
      setTransitionInformation(informationData);
    });
  }, [accessToken, researchProjectId, useDecryptedHashes]);

  return (
    <FlexTableBox>
      <BaseGrid
        details={details}
        identifiersBySampleId={identifiersBySampleId}
        transitionInformation={transitionInformation}
        disableSampleHierarchyGridCell={false}
        groupBySample={groupBySample}
        loadingProps={[loadingState, setLoadingState]}
        leftActions={
          <Box display='flex' flexDirection='row' alignItems='center'>
            <DecryptForeignHashesSwitch decryptedHashesProps={[useDecryptedHashes, setUseDecryptedHashes]} />
            <CustomizableSwitch
              title={t('groupBySample')}
              checked={groupBySample}
              onChange={() => {
                setGroupBySample(!groupBySample);
              }}
            />
          </Box>
        }
      />
    </FlexTableBox>
  );
};

export const SampleJourneyInformaticsResultGrid = ({
  identifiersBySampleId,
  transitionInformation,
  selectedSampleIds,
  loadingProps,
  leftActions,
  rightActions,
}: {
  identifiersBySampleId: Dictionary<string>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  selectedSampleIds: ReadonlyArray<string>;
  loadingProps: LoadingProps;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}) => {
  const { accessToken } = useAuth();

  const [, setLoadingState] = loadingProps;
  const [details, setDetails] = useState<ReadonlyArray<SampleJourneyInformaticsResultDetails>>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      const details = flatMap(
        await Promise.all(selectedSampleIds.map(async id => await GetInformaticsResultDetails(id, accessToken)))
      );

      setDetails(details);
    });
  }, [selectedSampleIds, accessToken, setLoadingState]);

  return (
    <BaseGrid
      details={details}
      identifiersBySampleId={identifiersBySampleId}
      transitionInformation={transitionInformation}
      disableSampleHierarchyGridCell={true}
      groupBySample={false}
      loadingProps={loadingProps}
      leftActions={leftActions}
      rightActions={rightActions}
    />
  );
};

const BaseGrid = ({
  identifiersBySampleId,
  transitionInformation,
  details,
  disableSampleHierarchyGridCell,
  groupBySample,
  loadingProps,
  leftActions,
  rightActions,
}: {
  identifiersBySampleId: Dictionary<string>;
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>;
  details: ReadonlyArray<SampleJourneyInformaticsResultDetails>;
  disableSampleHierarchyGridCell: boolean;
  groupBySample: boolean;
  loadingProps: LoadingProps;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}) => {
  const apiRef = useGridApiRef();

  const informaticsReferences = useInformaticsReferences();
  const informaticsPipelines = useInformaticsPipelines();

  const columns = useColumns(disableSampleHierarchyGridCell);
  const rows = useRows(
    transitionInformation,
    details,
    identifiersBySampleId,
    informaticsReferences,
    informaticsPipelines
  );

  return (
    <SampleTrackingGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      density='compact'
      disableRowSelectionOnClick
      enableCustomAutoColumnResizing={true}
      initialState={{
        columns: {
          columnVisibilityModel: {
            sampleId: false,
            foreignHash: false,
            sampleBbid: false,
          },
        },
        sorting: {
          sortModel: [
            { field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' },
            { field: referenceType, sort: 'asc' },
          ],
        },
      }}
      getTreeDataPath={row => {
        return row?.groupBy;
      }}
      treeData={groupBySample}
      groupingColDef={() => {
        return {
          headerName: '',
          headerAlign: 'center',
          align: 'right',
          maxWidth: 10,
          valueFormatter: () => '',
        };
      }}
      defaultGroupingExpansionDepth={100}
      cacheKey={'sample-journey-informatics-result-grid'}
      loadingProps={loadingProps}
      defaultColumnOrdering={[
        sampleIdentifier,
        pipeline,
        referenceType,
        computedValues,
        depths,
        multiQc,
        sequenceId,
        sequenceRunId,
        isDeliveryCandidate,
      ]}
      leftActions={leftActions}
      rightActions={rightActions}
      enableCustomAutoColumnResizingOverride={true}
    />
  );
};

const useRows = (
  transitionInformation: ReadonlyArray<SampleJourneyTransitionInformation>,
  informaticsResultDetails: ReadonlyArray<SampleJourneyInformaticsResultDetails>,
  identifiersBySampleId: Dictionary<string>,
  informaticsReferences: ReadonlyArray<InformaticsReference>,
  informaticsPipelines: ReadonlyArray<InformaticsPipeline>
): ReadonlyArray<Row> => {
  return useMemo(() => {
    let id = 0;
    const informationBySampleId = keyBy(transitionInformation, t => t.sampleId);

    return informaticsResultDetails.flatMap(details => {
      return details.details.map(result => {
        return {
          ...result.informaticsPipelineRun,
          ...result.informaticsPipelineResult,
          computedValues:
            merge(
              result.computedValues?.perReferenceValues[result.informaticsPipelineResult.referenceTypeId] ?? {},
              result.computedValues?.globalValues ?? {}
            ) ?? {},
          sampleIdentifier: identifiersBySampleId[details.sampleId],
          ...informationBySampleId[details.sampleId],
          sampleJourneyId: informationBySampleId[details.sampleId]?.sampleJourneyId,
          id: id++,
          reference: find(
            informaticsReferences,
            i => i.referenceTypeId === result.informaticsPipelineResult.referenceTypeId
          ),
          pipeline: find(informaticsPipelines, i => i.pipelineId === result.informaticsPipelineRun.pipelineId),
          sequenceRunId: result.informaticsPipelineRun.sequenceRunId,
          sampleId: result.informaticsPipelineRun.sampleId,
          groupBy: [
            identifiersBySampleId[details.sampleId],
            result.informaticsPipelineResult.informaticsPipelineResultId,
          ],
        };
      });
    });
  }, [
    transitionInformation,
    informaticsResultDetails,
    identifiersBySampleId,
    informaticsReferences,
    informaticsPipelines,
  ]);
};

const useColumns = (disableSampleHierarchyGridCell: boolean): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: sampleIdentifier,
        headerName: t(sampleIdentifier),
        headerAlign: 'left',
        align: 'left',
        ...(disableSampleHierarchyGridCell
          ? {}
          : {
              renderCell: params =>
                renderSampleHierarchyGridCell({
                  ...params,
                  sampleId: params.row.sampleId,
                  displayValue: <span>{params.value}</span>,
                }),
            }),
      },
      {
        field: pipeline,
        headerName: t(pipeline),
        valueGetter: params => params?.row?.pipeline?.name ?? '',
      },
      {
        field: referenceType,
        headerName: t(referenceType),
        valueGetter: params => params?.row?.reference?.name ?? '',
      },
      {
        field: computedValues,
        headerName: t(computedValues),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 100,
        renderCell: renderGridCellInformaticsComputedValues,
      },
      {
        field: depths,
        headerName: t(depths),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 100,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: renderGridCellInformaticsSequenceDepths,
      },
      {
        field: multiQc,
        headerName: t(multiQc),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 80,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        valueGetter: params => params.row?.sequenceRunId,
        renderCell: renderCellMultiQcDownloadButton,
      },
      {
        field: sequenceId,
        headerName: t(sequenceId),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 130,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: sequenceRunId,
        headerName: t(sequenceRunId),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 130,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: isDeliveryCandidate,
        headerName: t(isDeliveryCandidate),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 150,
        valueFormatter: ({ value }) => value && t(value),
        renderCell: params => {
          if (params.row.isDeliveryCandidate === 'notYetDecided') {
            return <></>;
          } else {
            return <>{t(params.value)}</>;
          }
        },
      },
      {
        field: sampleId,
        headerName: t(sampleId),
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: foreignHash,
        headerName: t(foreignHash),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        maxWidth: 300,
        valueFormatter: ({ value }) => value && truncateForeignHash(value),
      },
      {
        field: sampleBbid,
        headerName: t(sampleBbid),
        headerAlign: 'left',
        align: 'left',
      },
    ],
    [t, disableSampleHierarchyGridCell]
  );
};
