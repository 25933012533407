import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { InformaticsQcThresholdEnum, Thresholds } from 'data/InformaticsQcData';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { InformaticsQcMetricsGridToolbar } from './InformaticsQcMetricsGridToolbar';
import { GridThresholdFilterOperators } from 'components/grid/columnFilters/GridThresholdFilterOperators';
import {
  compactGridHeaderClassName,
  computedValues,
  depths,
  isDeliveryCandidate,
  labAssignedSampleId,
  maxDepth,
  meanDepth,
  medianCoverage,
  multiQc,
  percentDuplication,
  percentGcContent,
  qualityCheckStatus,
  rawTotalSequences,
  readsAligned,
  readsMapped,
  readsMappedPercent,
  readsProperlyPairedPercent,
  referenceType,
  sampleId,
  seenBases,
  selectionStatus,
  sequenceRunId,
  totalBases,
  totalReads,
  trimmedR1PercentGc,
  trimmedR2PercentGc,
  uniquelyMapped,
  uniquelyMappedPercent,
} from 'util/Constants';
import { renderCellMultiQcDownloadButton } from 'components/grid/GridCellMultiQcDownloadButton';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import { Dictionary, find, uniq } from 'lodash';
import { DeliveryCandidateType, InformaticsPipelineResult } from '../../data/InformaticsQualityCheckData';
import { InformaticsCheckByData, InformaticsCheckByTabs } from './InformaticsCheckByModal';
import useInformaticsReferences from '../../components/hooks/UseInformaticsReferences';
import { InformaticsReference } from '../../data/InformaticsData';
import { renderGridCellInformaticsComputedValues } from '../../components/grid/GridCellInformaticsComputedValues';
import { renderGridCellInformaticsSequenceDepths } from '../../components/grid/GridCellInformaticsSequenceDepths';

export interface InformaticsQcMetricsGridProps {
  seqType: InformaticsCheckByTabs;
  data: InformaticsCheckByRow[];
  onDeliveryCandidateMarking(selectionModel: GridRowSelectionModel, selection: DeliveryCandidateType): void;
}

export type InformaticsCheckByRow = InformaticsCheckByData &
  InformaticsPipelineResult & {
    groupBy: string[];
    computedValues: Dictionary<object>;
    id: number;
    isDeliveryCandidate: DeliveryCandidateType;
  };

export const InformaticsQcMetricsGrid = ({
  seqType,
  data,
  onDeliveryCandidateMarking,
}: InformaticsQcMetricsGridProps) => {
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const informaticsReferences = useInformaticsReferences();
  const columns = useColumns(seqType);
  const rows = useRows(data, informaticsReferences);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [selectedSampleIds, setSelectedSampleIds] = useState<string[]>([]);

  const handleFilterModelChange = () => {
    setSelectionModel([]);
  };

  const handleQcButtonClick = (selection: DeliveryCandidateType) => {
    if (!selectedSampleIds) {
      return;
    }

    onDeliveryCandidateMarking(selectionModel ?? [], selection);
    setSelectionModel([]);
  };

  useEffect(() => {
    if (!selectionModel) {
      return;
    }

    const sampleIds: string[] = [];
    selectionModel.forEach(selected => {
      const id = apiRef.current?.getRow(selected)?.sampleId;
      if (id) {
        sampleIds.push(id);
      }
    });

    setSelectedSampleIds(uniq(sampleIds));
  }, [selectionModel, apiRef]);

  return (
    <CompactGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      checkboxSelection
      disableRowSelectionOnClick
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
      onFilterModelChange={handleFilterModelChange}
      density='compact'
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{
        sampleId: false,
      }}
      getTreeDataPath={row => {
        return row?.groupBy;
      }}
      treeData={true}
      groupingColDef={() => {
        return {
          headerName: t('sampleIdentifierFull'),
          headerAlign: 'center',
          align: 'right',
          sortable: true,
          filterable: true,
          valueFormatter: params => {
            const node = params.api.getRowNode(params.id!)!;

            if (node.depth === 1) {
              return '';
            }

            return 'groupingKey' in node ? node.groupingKey : '';
          },
        };
      }}
      defaultGroupingExpansionDepth={1}
      initialState={{
        pinnedColumns: {
          left: ['__check__', labAssignedSampleId],
          right: [qualityCheckStatus],
        },
        sorting: {
          sortModel: [
            { field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' },
            { field: referenceType, sort: 'asc' },
          ],
        },
      }}
      components={{
        Toolbar: InformaticsQcMetricsGridToolbar,
      }}
      componentsProps={{
        toolbar: {
          onQcButtonClick: handleQcButtonClick,
          anyRowSelected: selectedSampleIds.length !== 0,
          apiRef: apiRef,
        },
      }}
      enableCustomAutoColumnResizing={true}
    />
  );
};

const useColumns = (seqType: InformaticsCheckByTabs): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const startColumns: GridColDef[] = [
      {
        field: sampleId,
        headerName: t(sampleId),
      },
      {
        field: referenceType,
        headerName: t(referenceType),
        filterable: false,
        valueGetter: params => params?.row?.reference?.name ?? '',
      },
      {
        field: computedValues,
        headerName: t(computedValues),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 100,
        renderCell: renderGridCellInformaticsComputedValues,
      },
      {
        field: depths,
        headerName: t(depths),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 100,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: renderGridCellInformaticsSequenceDepths,
      },
    ];

    const dnaColumns: GridColDef[] =
      seqType === 'DNA'
        ? [
            {
              field: percentDuplication,
              headerName: t(percentDuplication),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.percentDuplication.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: medianCoverage,
              headerName: t(medianCoverage),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              minWidth: 100,
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.medianCoverage.getThresholdEnum(params.value as number));
              },
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: rawTotalSequences,
              headerName: t(rawTotalSequences),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.rawTotalSequences.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => Number(getMetricPerM(value)?.toFixed(2)).toLocaleString(),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: readsMapped,
              headerName: t(readsMapped),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.readsMapped.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => Number(getMetricPerM(value)?.toFixed(2)).toLocaleString(),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: readsMappedPercent,
              headerName: t(readsMappedPercent),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.readsMappedPercent.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value?.toFixed(2),
            },
            {
              field: percentGcContent,
              headerName: t(percentGcContent),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.percentGcContent.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: readsProperlyPairedPercent,
              headerName: t(readsProperlyPairedPercent),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(
                      Thresholds.readsProperlyPairedPercent.getThresholdEnum(params.value as number)
                    );
              },
              valueFormatter: ({ value }) => value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
          ]
        : [];

    const rnaColumns: GridColDef[] =
      seqType === 'RNA'
        ? [
            {
              field: percentDuplication,
              headerName: t(percentDuplication),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.percentDuplication.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: totalReads,
              headerName: t(totalReads),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(
                      Thresholds.totalReads.getThresholdEnum(getMetricPerM(params.value as number))
                    );
              },
              valueFormatter: ({ value }) => value && Number(value?.toFixed(2)).toLocaleString(),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: readsAligned,
              headerName: t(readsAligned),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(
                      Thresholds.readsAligned.getThresholdEnum(getMetricPerM(params.value as number))
                    );
              },
              valueFormatter: ({ value }) => value && Number(value?.toFixed(2)).toLocaleString(),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: uniquelyMapped,
              headerName: t(uniquelyMapped),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(
                      Thresholds.uniquelyMapped.getThresholdEnum(getMetricPerM(params.value as number))
                    );
              },
              valueFormatter: ({ value }) => value && Number(value?.toFixed(2)).toLocaleString(),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: uniquelyMappedPercent,
              headerName: t(uniquelyMappedPercent),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(
                      Thresholds.uniquelyMappedPercent.getThresholdEnum(params.value as number)
                    );
              },
              valueFormatter: ({ value }) => value && value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: trimmedR1PercentGc,
              headerName: t(trimmedR1PercentGc),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.trimmedR1PercentGc.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value && value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
            {
              field: trimmedR2PercentGc,
              headerName: t(trimmedR2PercentGc),
              headerClassName: compactGridHeaderClassName,
              width: 125,
              headerAlign: 'center',
              align: 'right',
              type: 'number',
              cellClassName: (params: GridCellParams) => {
                return params.value == null
                  ? ''
                  : getThresholdCellClassName(Thresholds.trimmedR2PercentGc.getThresholdEnum(params.value as number));
              },
              valueFormatter: ({ value }) => value && value?.toFixed(2),
              filterOperators: GridThresholdFilterOperators,
            },
          ]
        : [];

    const tnaColumns: GridColDef[] =
      seqType === 'TNA' || seqType === 'Other'
        ? [
            {
              field: maxDepth,
              headerName: t(maxDepth),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              valueFormatter: ({ value }) => value?.toFixed(2),
            },
            {
              field: meanDepth,
              headerName: t(meanDepth),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              valueFormatter: ({ value }) => value?.toFixed(2),
            },
            {
              field: seenBases,
              headerName: t(seenBases),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              valueFormatter: ({ value }) => value?.toFixed(2),
            },
            {
              field: totalBases,
              headerName: t(totalBases),
              headerClassName: compactGridHeaderClassName,
              headerAlign: 'center',
              align: 'right',
              width: 110,
              type: 'number',
              valueFormatter: ({ value }) => value?.toFixed(2),
            },
          ]
        : [];

    const endColumns: GridColDef[] = [
      {
        field: selectionStatus,
        headerName: t(selectionStatus),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: ({ value }) => value && t(value),
      },
      {
        field: sequenceRunId,
        headerName: t(multiQc),
        headerClassName: compactGridHeaderClassName,
        maxWidth: 80,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: renderCellMultiQcDownloadButton,
      },
      {
        field: isDeliveryCandidate,
        headerName: t(isDeliveryCandidate),
        headerClassName: compactGridHeaderClassName,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 150,
        valueFormatter: ({ value }) => value && t(value),
        renderCell: params => {
          if (params.row.isDeliveryCandidate === 'notYetDecided') {
            return <></>;
          } else {
            return <>{t(params.value)}</>;
          }
        },
      },
    ];

    return startColumns.concat(dnaColumns, rnaColumns, tnaColumns, endColumns);
  }, [seqType, t]);
};

function getThresholdCellClassName(value: InformaticsQcThresholdEnum) {
  return clsx('livinglab-grid', {
    fail: value === InformaticsQcThresholdEnum.Fail,
    warn: value === InformaticsQcThresholdEnum.Warn,
    pass: value === InformaticsQcThresholdEnum.Pass,
  });
}

const useRows = (data: InformaticsCheckByRow[], informaticsReferences: ReadonlyArray<InformaticsReference>) => {
  return useMemo(() => {
    let rows: any[] = [];

    rows = data.map(d => {
      return {
        ...d,
        ...d.computedValues,
        reference: find(informaticsReferences, i => i.referenceTypeId === d.referenceTypeId),
      };
    });

    return rows;
  }, [data, informaticsReferences]);
};

function getMetricPerM(metric?: number) {
  if (!metric) {
    return metric;
  }
  return metric / 1000;
}
