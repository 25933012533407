import useAuth from '../../auth/UseAuth';
import { useEffect, useState } from 'react';
import { LoadingState, LoadState } from '../LoadingStateUtil';
import { UseStateSetter } from '../../util/TypeUtil';
import { GetLlCustomers, LlCustomer } from '../../data/LlCustomerData';

export const useCustomers = (
  setLoadingState?: UseStateSetter<LoadingState>
): [ReadonlyArray<LlCustomer>, () => void] => {
  const { accessToken } = useAuth();
  const [customers, setCustomers] = useState<ReadonlyArray<LlCustomer>>([]);
  const [refresh, refreshTrigger] = useState<boolean>(true);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetLlCustomers(accessToken);
      setCustomers(data);
    });
  }, [refresh, accessToken, setLoadingState]);

  function refreshCallBack() {
    refreshTrigger(!refresh);
  }

  return [customers, refreshCallBack];
};
