import { BaseGet, BaseGetWithParams, BasePostVoid } from './BaseData';
import { appSettings } from '../AppSettings';
import { Migration } from './MigrationsData';
import qs from 'qs';

export type SuperDuperFiestaPipeline = {
  pipelineId: string;
  pipelineStatusId?: string;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaEntity = {
  entityId: string;
  displayName: string;
  name: string;
  tablePrefix: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaExecutedPipelineHistory = {
  executedPipelineHistoryId: string;
  pipelineId: string;
  executedAt: string;
  executionDurationMs: number;
  state: string;
};

export type SuperDuperFiestaStage = {
  stageId: string;
  name: string;
  displayName: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaStep = {
  stepId: string;
  stepTypeId: string;
  entityId: string;
  dataSourceId?: string;
  name: string;
  description?: string;
  needsReview: boolean;
  queryCondition?: string;
  queryJoin?: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  dataClassId?: number;
};

export type SuperDuperFiestaPipelineConfig = {
  entities: ReadonlyArray<string>;
  runSubsequentStages: boolean;
  startAtStage?: string;
  startAtStep?: string;
};

export type SuperDuperFiestaExecutedQueryHistory = {
  executedQueryHistoryId: string;
  executedPipelineHistoryId: string;
  query: string;
  stepId?: string;
  executedAt: string;
  executionDurationMs?: number;
  state: string;
  rowsAffected?: number;
};

export type SuperDuperFiestaDataSource = {
  dataSourceId: string;
  name: string;
  displayName: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SuperDuperFiestaDataClass = {
  dataClassId: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SuperDuperFiestaPipelineReviewIssues = {
  issueName: string;
  issueId: number;
  issueCount: number;
};

export type SuperDuperFiestaPipelineReviewTypes = {
  dataClassId: number;
};

export type PipelineReviewConfig = {
  dataClassId?: number;
  dataSourceId?: string;
  entity: string;
  stage?: string;
  pageNumber?: string;
  pageSize?: string;
  selectedIssueId?: string;
  sortBy?: string[];
  logicOperator?: string;
  filterBy?: FilterParamsConfig;
};

export type FilterParamsConfig = {
  [key: string]: string | string[];
};

export type PipelineRulesConfig = {
  pipelineId: string;
  stageId: string;
  entityId: string;
};

export type PipelineRules = {
  executionOrder: number;
  name: string;
  description?: string;
  stepTypeName: string;
  entityName: string;
  dataSourceName?: string;
  dataClassName?: string;
  issueName?: string;
  needsReview: boolean;
  queryCondition?: string;
  queryJoin?: string;
  queryGroupBy?: string;
  field?: string;
  value?: string;
  rowsAffected?: number;
  executionDurationMs?: number;
  state?: string;
  executedAt?: string;
  createdBy: string;
  createdAt: string;
  hierarchy?: string[];
  stepUpdateValueId?: string;
};

export async function GetPipelines(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaPipeline>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Pipeline`, accessToken);
}

export async function GetEntities(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaEntity>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Entity`, accessToken);
}

export async function GetDataSources(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaDataSource>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/DataSource`, accessToken);
}

export async function GetDataClasses(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaDataClass>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/DataClass`, accessToken);
}

export async function GetPipelineExecutionHistory(
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaExecutedPipelineHistory>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/History`, accessToken);
}

export async function GetQueryExecutionHistory(
  executedPipelineHistoryId: string,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaExecutedQueryHistory>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/QueryHistory/${executedPipelineHistoryId}`,
    accessToken
  );
}

export async function GetStages(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaStage>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Stage`, accessToken);
}

export async function GetSteps(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaStep>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Step`, accessToken);
}

export async function ExecutePipeline(
  pipelineId: string,
  config: SuperDuperFiestaPipelineConfig,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/Execute/PipelineId/${pipelineId}`,
    config,
    accessToken
  );
}

export async function GetPipelineReview(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<object>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview`,
    params,
    accessToken
  );
}

export async function GetPipelineReviewIssues(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaPipelineReviewIssues>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview/PipelineReviewIssues`,
    params,
    accessToken
  );
}

export async function GetPipelineReviewTypes(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaPipelineReviewTypes>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview/PipelineReviewTypes`,
    params,
    accessToken
  );
}

export async function GetPipelineRules(
  queryParams: PipelineRulesConfig,
  accessToken?: string
): Promise<ReadonlyArray<PipelineRules>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineRules`,
    params,
    accessToken
  );
}

export async function GetMigrations(accessToken?: string): Promise<ReadonlyArray<Migration>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations`, accessToken);
}
export async function ExecuteMigrations(accessToken?: string): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations`, undefined, accessToken);
}

export async function RepairMigrations(accessToken?: string): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations/Repair`, undefined, accessToken);
}
