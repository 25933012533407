import React, { useCallback, useEffect, useState } from 'react';
import { ActionButton, DialogOpenButton } from '../../components/DialogOpenButton';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import useAuth from '../../auth/UseAuth';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { CancelButton } from '../../components/CancelButton';
import { PrimaryButton } from '../../components/PrimaryButton';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import DraggableList, { DraggableItem } from '../../components/DraggableList';
import { useParams } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { TransitionIcon } from '../../util/TransitionUtil';
import { TransitionType } from '../../data/SampleTrackingData';
import { FlexBox } from '../../components/FlexBox';
import useTransitionEnums from '../../components/hooks/UseTransitionEnums';
import { filter, find, map, orderBy, remove, some } from 'lodash';
import {
  DefaultExhaustionApproachValues,
  ExhaustionApproach,
  SampleActionOnCheckBy,
  SampleActionOnCheckByValues,
  TransitionEnum,
} from '../../data/ReferenceData';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { UseState } from '../../util/TypeUtil';
import { useConfiguredTransitions } from '../../components/hooks/UseConfiguredTransitions';
import {
  ConfiguredTransition,
  ProcessConfiguredTransition,
  ProcessConfiguredTransitions,
  SetConfiguredTransitionsToDefaultAsync,
} from '../../data/ConfiguredTransitionData';
import { useUserPermissions } from '../../auth/UseUserPermissions';

export interface SampleTrackingPathConfigurationModalProps {
  onClose?: (hasChanges: boolean) => void;
}

type Step = DraggableItem & {
  base?: ConfiguredTransition;
};

interface CreateStepForm {
  name?: string;
  transitionType?: string;
  transitionEnum?: TransitionEnum;
}

export const SampleTrackingPathConfigurationModal = ({
  onClose = () => {},
}: SampleTrackingPathConfigurationModalProps) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();
  let { researchProjectId } = useParams() as { researchProjectId: string };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [draggableItems, setDraggableItems] = useState<Step[]>([]);
  const [createStepForm, setCreateStepForm] = useState<CreateStepForm>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const transitionEnums = useTransitionEnums();
  const [configuredTransitions, configuredTransitionsRefreshTrigger] = useConfiguredTransitions(
    researchProjectId,
    setLoadingState
  );

  const [currentBeingEdited, setCurrentBeingEdited] = useState<ConfiguredTransition | undefined>();

  let processDraggableItemsUpdate: (data: Step[]) => void = useCallback(() => {}, []);

  const removeItem = useCallback(
    (toCompare: string, items: Step[]) => {
      const data = remove(items, p => {
        return p.id !== toCompare;
      });

      processDraggableItemsUpdate(data);
      setHasChanges(true);
    },
    [processDraggableItemsUpdate, setHasChanges]
  );
  const setEditItem = useCallback(
    (toCompare: string, items: Step[]) => {
      const data = find(items, p => {
        return p.id === toCompare;
      });

      setCurrentBeingEdited(data?.base);
    },
    [setCurrentBeingEdited]
  );

  const setChildrenOnDraggableItem = useCallback(
    (i: Step, items: Step[]): Step => {
      i.children = (
        <FlexBox sx={{ width: '30%' }}>
          <ActionButton
            testId='configured-transition-delete'
            onClick={() => removeItem(i.id, items)}
            disabled={!i.base?.canBeDeleted}
            sx={{ paddingRight: 0 }}
          >
            <DeleteIcon />
          </ActionButton>
          <ActionButton
            testId='configured-transition-edit'
            onClick={() => setEditItem(i.id, items)}
            disabled={!i.base?.canBeEdited && !i.base?.canDisplayNameBeEdited}
            sx={{ paddingLeft: 0 }}
          >
            <EditIcon />
          </ActionButton>
        </FlexBox>
      );

      return i;
    },
    [removeItem, setEditItem]
  );

  processDraggableItemsUpdate = useCallback(
    (data: Step[]) => {
      // the draggable items have a reference onto themselves in order to support deleting
      // therefore after generating the list we need to update each element to a reference of the new list
      data.forEach(i => setChildrenOnDraggableItem(i, data));

      setDraggableItems(data);
    },
    [setChildrenOnDraggableItem, setDraggableItems]
  );

  function refreshPostEdit() {
    processDraggableItemsUpdate([
      ...map(draggableItems, i => {
        return {
          ...i,
          primary: i.base?.displayName ?? i.primary,
        };
      }),
    ]);
  }

  const toDraggableItem = useCallback(
    (i: ConfiguredTransition): Step => {
      return {
        id: i.displayName,
        primary: i.displayName,
        secondary: t(i.transitionEnum.name as any),
        icon: (
          <TransitionIcon transitionType={i.transitionEnum.name as TransitionType} variant='h4' color={'primary'} />
        ),
        children: <></>,
        base: i,
      };
    },
    [t]
  );

  useEffect(() => {
    if (!configuredTransitions) {
      return;
    }
    const data = orderBy(
      configuredTransitions.map(i => {
        return toDraggableItem(i);
      }),
      i => i.base?.transitionOrder ?? -1
    );

    processDraggableItemsUpdate(data);
  }, [configuredTransitions, processDraggableItemsUpdate, toDraggableItem]);

  function handleClose(hasChanges: boolean = false) {
    setModalOpen(false);
    setHasChanges(false);
    setCreateStepForm({});
    onClose(hasChanges);
    setLoadingState({ status: 'Complete' });
    configuredTransitionsRefreshTrigger();
    setCurrentBeingEdited(undefined);
  }

  function handleAddStep() {
    const transitionEnum = createStepForm.transitionEnum;

    const data = [
      ...draggableItems,
      {
        id: createStepForm.name ?? '',
        primary: createStepForm.name ?? '',
        secondary: createStepForm.transitionType ?? '',
        icon: (
          <TransitionIcon
            transitionType={createStepForm.transitionEnum?.name as TransitionType}
            variant='h4'
            color={'primary'}
          />
        ),
        children: <></>,
        base: {
          researchProjectId: researchProjectId,
          configuredTransitionId: undefined,
          transitionOrder: -1,
          transitionEnum: transitionEnum,
          displayName: createStepForm.name ?? '',
          canBeEdited: true,
          canDisplayNameBeEdited: true,
          enableCheckByFailedReason: transitionEnum?.defaultEnableCheckByFailedReason,
          enableSampleExhaustion: transitionEnum?.defaultEnableSampleExhaustion,
          enableSampleVolumeOnCheckBy: transitionEnum?.defaultEnableSampleVolumeOnCheckBy,
          exhaustionApproach: transitionEnum?.defaultExhaustionApproach,
          sampleActionOnCheckBy: transitionEnum?.defaultSampleActionOnCheckBy,
          isEndState: false,
        },
      },
    ];

    setHasChanges(true);
    processDraggableItemsUpdate(data as Step[]);
    setCreateStepForm({});
  }

  function handleSubmit() {
    ErrorManagement('Loading', setLoadingState, async () => {
      const nonDeleted: string[] = [];

      const toSave = draggableItems.map((i, index): ProcessConfiguredTransition => {
        nonDeleted.push(i.base?.configuredTransitionId ?? 'random_place_holder');

        return {
          researchProjectId: researchProjectId,
          configuredTransitionId: i.base?.configuredTransitionId,
          transitionOrder: index,
          transitionEnumId: i.base?.transitionEnum.transitionEnumId ?? '',
          displayName: i.base?.displayName ?? '',
          isDeleted: false,
          enableCheckByFailedReason: i.base?.enableCheckByFailedReason ?? false,
          enableSampleExhaustion: i.base?.enableSampleExhaustion ?? false,
          enableSampleVolumeOnCheckBy: i.base?.enableSampleVolumeOnCheckBy ?? false,
          exhaustionApproach: i.base?.exhaustionApproach ?? '',
          sampleActionOnCheckBy: i.base?.sampleActionOnCheckBy ?? '',
          isEndState: i.base?.isEndState ?? false,
        };
      });

      const toDelete = filter(configuredTransitions, i => !nonDeleted.includes(i.configuredTransitionId)).map(
        (i): ProcessConfiguredTransition => {
          return {
            ...i,
            researchProjectId: researchProjectId,
            transitionEnumId: i.transitionEnum.transitionEnumId,
            isDeleted: true,
          };
        }
      );

      await ProcessConfiguredTransitions([...toSave, ...toDelete], accessToken);
      handleClose(true);
    });
  }

  function handleResetToDefault() {
    ErrorManagement('Loading', setLoadingState, async () => {
      await SetConfiguredTransitionsToDefaultAsync(researchProjectId, accessToken);
      handleClose(true);
    });
  }

  function handleDragEnd({ destination, source }: DropResult) {
    if (!destination) {
      return;
    }

    const result = Array.from(draggableItems);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    processDraggableItemsUpdate(result);
    setHasChanges(true);
  }

  return (
    <>
      <DialogOpenButton title={t('editPath')} onClick={() => setModalOpen(true)}>
        <SettingsOutlinedIcon />
      </DialogOpenButton>
      <Dialog open={modalOpen} onClose={() => handleClose()} maxWidth={'md'}>
        <DialogTitle>
          <DialogCloseButton onClick={() => handleClose()} />
          {t('pathConfigTitle')}
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{t('pathConfigDescription')}</Typography>
          <FlexBox>
            <Box sx={{ marginTop: 2, width: '40%', flexGrow: 1, marginRight: 1 }}>
              <Typography variant={'h5'} sx={{ marginBottom: 1 }}>
                {t('pathConfigOrder')}{' '}
              </Typography>
              <Box sx={{ maxHeight: '50vh', overflow: 'scroll' }}>
                <DraggableList items={draggableItems} onDragEnd={handleDragEnd}></DraggableList>
              </Box>
            </Box>
            <FlexBox sx={{ flexDirection: 'column', width: '60%' }}>
              {currentBeingEdited === undefined && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant={'h5'}>{t('pathConfigNew')}</Typography>
                  <Box component='form'>
                    <TextField
                      fullWidth
                      label={t('nameRequired')}
                      margin='normal'
                      type='text'
                      variant='outlined'
                      value={createStepForm.name ?? ''}
                      onChange={value => setCreateStepForm({ ...createStepForm, name: value.target.value })}
                    />
                    <Autocomplete
                      fullWidth
                      options={transitionEnums.map(value => t(value.name as any))}
                      renderInput={params => <TextField {...params} label={t('stepType')} margin='normal' />}
                      onChange={(event, value) =>
                        setCreateStepForm({
                          ...createStepForm,
                          transitionType: value,
                          transitionEnum: find(transitionEnums, o => t(o.name as any) === value),
                          name:
                            createStepForm.name ??
                            t(find(transitionEnums, o => t(o.name as any) === value)?.name as any),
                        })
                      }
                      value={createStepForm.transitionType}
                    />
                    <FlexBox flexDirection={'row-reverse'}>
                      <PrimaryButton
                        disabled={
                          loadingState.status === 'Loading' ||
                          !createStepForm.name ||
                          !createStepForm.transitionType ||
                          some(draggableItems, i => i.base?.displayName === createStepForm.name)
                        }
                        onClick={handleAddStep}
                      >
                        {t('addStep')}
                      </PrimaryButton>
                    </FlexBox>
                  </Box>
                </Box>
              )}
              {currentBeingEdited && (
                <EditForm
                  step={currentBeingEdited}
                  hasChangesProps={[hasChanges, setHasChanges]}
                  currentBeingEditedProps={[currentBeingEdited, setCurrentBeingEdited]}
                  refreshPostEdit={refreshPostEdit}
                />
              )}
            </FlexBox>
          </FlexBox>
        </DialogContent>

        <LoadingIndicator loadingState={loadingState} margin={'LRT'} />
        <ErrorIndicator loadingState={loadingState} />

        <DialogActions>
          <FlexBox sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box>
              {!some(configuredTransitions, i => !i.canBeDeleted) && (
                <PrimaryButton disabled={loadingState.status === 'Loading'} onClick={handleResetToDefault}>
                  {t('resetToDefault')}
                </PrimaryButton>
              )}
            </Box>
            <Box>
              <CancelButton onClick={() => handleClose()} />
              <PrimaryButton disabled={loadingState.status === 'Loading' || !hasChanges} onClick={handleSubmit}>
                {t('submit')}
              </PrimaryButton>
            </Box>
          </FlexBox>
        </DialogActions>
      </Dialog>
    </>
  );
};

function EditForm({
  step,
  hasChangesProps,
  currentBeingEditedProps,
  refreshPostEdit,
}: {
  step: ConfiguredTransition;
  hasChangesProps: UseState<boolean>;
  currentBeingEditedProps: UseState<ConfiguredTransition | undefined>;
  refreshPostEdit: () => void;
}) {
  const { t } = useMemoTranslation();
  const { hasSampleTrackingAdminAccess } = useUserPermissions();

  const [stepToEdit, setStepToEdit] = useState<ConfiguredTransition>(step);
  const [, setHasChanges] = hasChangesProps;
  const [, setCurrentBeingEditedProps] = currentBeingEditedProps;

  function handleEditStep() {
    step.displayName = stepToEdit.displayName;
    step.sampleActionOnCheckBy = stepToEdit.sampleActionOnCheckBy;
    step.enableCheckByFailedReason = stepToEdit.enableCheckByFailedReason;
    step.enableSampleExhaustion = stepToEdit.enableSampleExhaustion;
    step.enableSampleVolumeOnCheckBy = stepToEdit.enableSampleVolumeOnCheckBy;
    step.exhaustionApproach = stepToEdit.exhaustionApproach;
    step.isEndState = stepToEdit.isEndState;
    setHasChanges(true);
    setCurrentBeingEditedProps(undefined);
    refreshPostEdit();
  }

  function handleCancel() {
    setCurrentBeingEditedProps(undefined);
  }

  useEffect(() => {
    setStepToEdit(step);
  }, [step]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant={'h5'}>{t('pathConfigEdit')}</Typography>
      <Typography sx={{ whiteSpace: 'pre-line' }}>{t('pathConfigEditDescription')}</Typography>
      <Box component='form'>
        <TextField
          fullWidth
          label={t('nameRequired')}
          margin='normal'
          type='text'
          variant='outlined'
          value={stepToEdit.displayName ?? ''}
          onChange={event => {
            setStepToEdit({ ...stepToEdit, displayName: event.target.value });
          }}
          disabled={!step.canDisplayNameBeEdited}
          data-testid='configured-transition-edit-displayName'
        />
        <>
          {step.transitionEnum.defaultSampleActionOnCheckBy !== 'notSupported' && (
            <Autocomplete
              fullWidth
              options={SampleActionOnCheckByValues}
              getOptionLabel={option => t(option as any)}
              renderInput={params => <TextField {...params} label={t('checkByAction')} margin='normal' />}
              value={stepToEdit.sampleActionOnCheckBy}
              onChange={(event, value) =>
                setStepToEdit({
                  ...stepToEdit,
                  sampleActionOnCheckBy: (value as SampleActionOnCheckBy) ?? 'notSupported',
                })
              }
              disabled={!step.canBeEdited}
              data-testid='configured-transition-edit-sampleActionOnCheckBy'
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={stepToEdit.enableCheckByFailedReason}
                onChange={() =>
                  setStepToEdit({ ...stepToEdit, enableCheckByFailedReason: !stepToEdit.enableCheckByFailedReason })
                }
                disabled={!step.canBeEdited}
                data-testid='configured-transition-edit-enableCheckByFailedReason'
              />
            }
            label={t('enableCheckByFailedReason')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stepToEdit.enableSampleExhaustion}
                onChange={() =>
                  setStepToEdit({ ...stepToEdit, enableSampleExhaustion: !stepToEdit.enableSampleExhaustion })
                }
                disabled={!step.canBeEdited}
                data-testid='configured-transition-edit-enableSampleExhaustion'
              />
            }
            label={t('enableSampleExhaustion')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stepToEdit.enableSampleVolumeOnCheckBy}
                onChange={() =>
                  setStepToEdit({
                    ...stepToEdit,
                    enableSampleVolumeOnCheckBy: !stepToEdit.enableSampleVolumeOnCheckBy,
                  })
                }
              />
            }
            label={t('enableSampleVolumeOnCheckBy')}
            data-testid='configured-transition-edit-enableSampleVolumeOnCheckBy'
            disabled={!step.canBeEdited}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stepToEdit.isEndState}
                onChange={() => setStepToEdit({ ...stepToEdit, isEndState: !stepToEdit.isEndState })}
              />
            }
            label={t('isEndState')}
            data-testid='configured-transition-edit-isEndState'
            disabled={!step.canBeEdited && !hasSampleTrackingAdminAccess}
          />
          <Autocomplete
            fullWidth
            options={DefaultExhaustionApproachValues}
            getOptionLabel={option => t(option as any)}
            renderInput={params => <TextField {...params} label={t('checkByExhaustionApproach')} margin='normal' />}
            value={stepToEdit.exhaustionApproach}
            onChange={(event, value) =>
              setStepToEdit({
                ...stepToEdit,
                exhaustionApproach: (value as ExhaustionApproach) ?? 'none',
              })
            }
            disabled={!stepToEdit.enableSampleExhaustion || !step.canBeEdited}
            data-testid='configured-transition-edit-exhaustionApproach'
          />
        </>
        <FlexBox flexDirection={'row-reverse'}>
          <PrimaryButton onClick={handleEditStep}>{t('saveEdits')}</PrimaryButton>
          <CancelButton onClick={handleCancel}>{t('cancel')}</CancelButton>
        </FlexBox>
      </Box>
    </Box>
  );
}
