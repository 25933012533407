import React, { useEffect } from 'react';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import {
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from '../../data/SuperDuperFiestaData';
import { FlexBox } from '../../components/FlexBox';
import useSuperDuperFiestaEntities from '../../components/hooks/UseSuperDuperFiestaEntities';
import useSuperDuperFiestaPipelines from '../../components/hooks/UseSuperDuperFiestaPipelines';
import useSuperDuperFiestaStages from '../../components/hooks/UseSuperDuperFiestaStages';
import { LoadingProps } from '../../components/LoadingStateUtil';
import { ActionButton } from '../../components/DialogOpenButton';

export const PipelineRules = ({
  loadingProps,
  selectedEntity,
  selectedPipeline,
  selectedStage,
  setSelectedEntity,
  setSelectedPipeline,
  setSelectedStage,
}: {
  loadingProps: LoadingProps;
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
  setSelectedEntity: React.Dispatch<React.SetStateAction<SuperDuperFiestaEntity | null>>;
  setSelectedPipeline: React.Dispatch<React.SetStateAction<SuperDuperFiestaPipeline | null>>;
  setSelectedStage: React.Dispatch<React.SetStateAction<SuperDuperFiestaStage | null>>;
}) => {
  const { t } = useMemoTranslation();
  const entities = useSuperDuperFiestaEntities();
  const pipelines = useSuperDuperFiestaPipelines();
  const stages = useSuperDuperFiestaStages();

  const filteredStages = ['promoteToAugmentation', 'promoteToSelection'];

  const [, setLoadingState] = loadingProps;

  useEffect(() => {
    return setSelectedStage(stages.find(s => s.name === 'transformation') || null);
  }, [stages, setSelectedStage]);

  useEffect(() => {
    return setSelectedEntity(entities.find(e => e.name === 'lab') || null);
  }, [entities, setSelectedEntity]);

  function handleClear() {
    setSelectedPipeline(null);
    setSelectedStage(null);
    setSelectedEntity(null);
    setLoadingState({ status: 'NotStarted' });
  }

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        value={selectedPipeline}
        options={pipelines}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        renderInput={params => <TextField {...params} label={t('pipeline')} margin='dense' required />}
        onChange={(_, value) => setSelectedPipeline(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedStage}
        options={stages.filter(s => !filteredStages.includes(s.name))}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        renderInput={params => <TextField {...params} label={t('stage')} margin='dense' required />}
        onChange={(_, value) => setSelectedStage(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedEntity}
        options={entities}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        renderInput={params => <TextField {...params} label={t('entity')} margin='dense' required />}
        onChange={(_, value) => setSelectedEntity(value)}
      />
      <ActionButton
        sx={{ p: 0 }}
        title={'Clear Filters'}
        onClick={() => {
          handleClear();
        }}
        disableMarginLeft={true}
      >
        <></>
      </ActionButton>
    </FlexBox>
  );
};
