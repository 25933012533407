import { appSettings } from 'AppSettings';
import { orderBy } from 'lodash';

export interface DiseaseArea {
  diseaseAreaId: string;
  name: string;
  availableToShowOnDashboard: boolean;
  showOnDashboardByDefault: boolean;
  icd10Codes: string[];
  selected?: boolean;
}

export async function GetDiseaseAreas(accessToken?: string): Promise<DiseaseArea[]> {
  let diseaseAreas: DiseaseArea[] = [];
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/DiseaseAreas`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const values: DiseaseArea[] = await response.json();
        values.forEach(value => (value.selected = true));
        diseaseAreas = [...orderBy(values, [(d: DiseaseArea) => d.name.toLowerCase()], ['asc'])];
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return diseaseAreas;
}
