import { StateCreator } from 'zustand';
import { PatientApprovalMeasurementDefinition } from '../../../../data/PatientApprovalData';

export interface MeasurementSlice {
  measurement: PatientApprovalMeasurementDefinition | undefined;
  setMeasurement: (measurement: PatientApprovalMeasurementDefinition | undefined) => void;
}

export const createMeasurementSlice: StateCreator<MeasurementSlice> = set => ({
  measurement: undefined,
  setMeasurement: measurement => set({ measurement }),
});
