import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import 'highcharts/modules/boost-canvas';
import HighChartsBoost from 'highcharts/modules/boost';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { PatientApprovalMeasurementDefinitionType } from '../../../../../data/PatientApprovalData';
import { dateUsingUtc, formatAsUtcDate, toExclusiveInterval, valueUsingUtc } from '../../../utils/dateUtils';
import { usePatientData, useShowDataWithIssues } from '../../../stores/dataStore';
import { fillWeeklyCountGaps } from '../../../helpers/dataTransforms';
import { addWeeks, differenceInWeeks, isWithinInterval } from 'date-fns';
import { useActiveDateRange, useGlobalDateRange, useSetActiveDateRange } from '../../../stores/dateRangeStore';
import { clamp } from 'lodash';
import useHorizontalSelectionRef from '../../../hooks/useHorizontalSelectionRef';
import { computeNewTickInterval } from '../../../utils/highchartsUtils';
import { dateRangesEqual } from '../../../models/DateRange';
import assertExhaustive from '../../../utils/assertExhaustive';

HighChartsBoost(Highcharts);

const Histogram: React.FC = () => {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReactRefObject>(null);

  const globalDateRange = useGlobalDateRange();
  const activeDateRange = useActiveDateRange();
  const setActiveDateRange = useSetActiveDateRange();

  // Update the zoom on the chart whenever the active date range changes.
  useEffect(() => {
    const min = valueUsingUtc([activeDateRange.earliest, globalDateRange.earliest], (a, g) => differenceInWeeks(a, g));
    const max = valueUsingUtc([activeDateRange.latest, globalDateRange.earliest], (a, g) => differenceInWeeks(a, g));
    chartComponentRef.current?.chart?.xAxis[0].setExtremes(min, max);
  }, [globalDateRange, activeDateRange, chartComponentRef]);

  const { loading, data: patientData } = usePatientData();

  const showDataWithIssues = useShowDataWithIssues();

  // Compute all data for the chart, filling in gaps with zeros.
  const [labels, setLabels] = useState<string[]>([]);
  const [labData, setLabData] = useState<number[]>([]);
  const [observationData, setObservationData] = useState<number[]>([]);
  const [medicationData, setMedicationData] = useState<number[]>([]);
  const [procedureData, setProcedureData] = useState<number[]>([]);
  const [problemData, setProblemData] = useState<number[]>([]);

  useEffect(() => {
    if (loading || !patientData) {
      setLabels([]);
      setLabData([]);
      setObservationData([]);
      setMedicationData([]);
      setProcedureData([]);
      setProblemData([]);
      return;
    }

    const x: [PatientApprovalMeasurementDefinitionType, React.Dispatch<React.SetStateAction<number[]>>][] = [
      ['lab', setLabData],
      ['observation', setObservationData],
      ['medication', setMedicationData],
      ['procedure', setProcedureData],
      ['problem', setProblemData],
    ];

    let hasSetLabels = false;
    for (const [type, setter] of x) {
      const countsOfType = patientData.weeklyCounts.filter(count => count.type === type);
      const filledData = fillWeeklyCountGaps(countsOfType, type, globalDateRange);
      setter(
        filledData.map(d => {
          switch (showDataWithIssues) {
            case 'noIssues':
              return d.noIssuesCount;
            case 'onlyIssues':
              return d.totalCount - d.noIssuesCount;
            case 'all':
              return d.totalCount;
            default:
              assertExhaustive(showDataWithIssues);
              throw new Error('Unreachable');
          }
        })
      );

      if (!hasSetLabels) {
        const labels = filledData.map(d => formatAsUtcDate(new Date(d.performedWeek)));
        setLabels(labels);
        hasSetLabels = true;
      }
    }
  }, [loading, patientData, globalDateRange, showDataWithIssues]);

  const selectionRef = useHorizontalSelectionRef();

  const labelWidthPx = 55;
  const [tickInterval, setTickInterval] = useState<number>(Number.MAX_SAFE_INTEGER);

  const [isZoomed, setIsZoomed] = useState(false);

  if (labData?.length === 0 && observationData?.length === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          border: '1px solid white',
          paddingBottom: 0,
        }}
        justifyContent='center'
        alignItems='center'
        display='flex'
      >
        <Typography variant='h5'>No available data</Typography>
      </Box>
    );
  }

  const fontStyle = (fontSize: number) => ({
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    textOverflow: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: `${fontSize}px`,
  });

  const xAxisPlotLines = [];

  if (patientData?.diagnoses) {
    let diagnosesBeforeStart = 0;

    for (const diagnosis of patientData.diagnoses) {
      const date = new Date(diagnosis.diagnosisDate);

      let index = -1;
      for (let i = 0; i < labels.length - 1; i++) {
        const startDate = new Date(`${labels[i]}T00:00:00.000Z`);
        const endDate = new Date(`${labels[i + 1]}T00:00:00.000Z`);
        const interval = toExclusiveInterval({ earliest: startDate, latest: endDate });
        if (isWithinInterval(date, interval)) {
          index = i;
          break;
        }
      }

      if (index === -1) {
        diagnosesBeforeStart++;
        continue;
      }

      xAxisPlotLines.push({
        color: diagnosis.issues.length === 0 ? theme.palette.warning.main : theme.palette.error.main,
        width: 1,
        value: index,
        label: {
          text: diagnosis.name,
          style: {
            ...fontStyle(10),
          },
        },
        zIndex: 5,
      });
    }

    if (diagnosesBeforeStart > 0) {
      xAxisPlotLines.push({
        color: theme.palette.warning.main,
        width: 1,
        value: 0,
        label: {
          text: `(${diagnosesBeforeStart})`,
          style: {
            ...fontStyle(10),
          },
        },
        zIndex: 5,
      });
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: theme.spacing(1),
      }}
    >
      <HighchartsReact
        containerProps={{
          style: {
            position: 'absolute',
            top: 0,
            left: theme.spacing(1),
            bottom: 0,
            right: theme.spacing(1),
          },
        }}
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'column',
            animation: false,
            marginTop: Number(theme.spacing(1).slice(0, -2)),
            marginBottom: Number(theme.spacing(3).slice(0, -2)),
            marginLeft: Number(theme.spacing(4).slice(0, -2)),
            marginRight: Number(theme.spacing(4).slice(0, -2)),
            zooming: {
              type: 'x',
              resetButton: {
                position: {
                  // We display our own button that looks nicer than the default Highcharts one. There doesn't seem to
                  // be a way to turn it off so we just move it off-screen.
                  x: -9999,
                },
              },
            },
            events: {
              render: function () {
                const chart = this as unknown as Highcharts.Chart;

                // Adjust the ticks whenever a render event occurs, which may have happened due to a resize.
                const newTickInterval = computeNewTickInterval(chart, labelWidthPx);
                if (newTickInterval !== null) {
                  setTickInterval(newTickInterval);
                }
              },
            },
          },
          boost: {
            enabled: true,
            seriesBoostThreshold: 1,
            pixelRatio: 0,
          },
          title: undefined,
          legend: {
            enabled: false,
          },
          xAxis: {
            categories: labels,
            lineColor: theme.palette.text.primary,
            labels: {
              overflow: 'allow',
              autoRotation: false,
              style: {
                ...fontStyle(11),
              },
              overlap: false,
              distance: Math.floor(Number(theme.spacing(0.75).slice(0, -2))),
              padding: 0,
              formatter: function () {
                const self = this as unknown as Highcharts.AxisLabelsFormatterContextObject;

                const value = self.value;
                if (typeof value !== 'string') {
                  return value;
                }

                return value.slice(0, -3);
              },
            },
            startOnTick: false,
            endOnTick: false,
            minPadding: 0,
            maxPadding: 0,
            tickLength: 5,
            tickWidth: 1,
            tickInterval: tickInterval,
            plotLines: xAxisPlotLines,
            events: {
              afterSetExtremes: function () {
                const self = this as unknown as Highcharts.Axis;

                // Ensure that the chart is always zoomed to an integer value so that we don't have partial weeks.
                // Setting this will fire another afterSetExtremes event with the new integer values.
                const curMin = self.min ?? 0;
                const curMax = self.max ?? 0;
                if (!Number.isInteger(curMin) || !Number.isInteger(curMax)) {
                  self.setExtremes(Math.floor(curMin), Math.ceil(curMax));
                  return;
                }

                // Compute the new active date range based on the currently-shown range.
                const newDateRange = {
                  earliest: dateUsingUtc([globalDateRange.earliest], d => addWeeks(d, curMin)),
                  latest: dateUsingUtc([globalDateRange.earliest], d => addWeeks(d, curMax)),
                };

                setActiveDateRange(newDateRange);
                setIsZoomed(!dateRangesEqual(newDateRange, globalDateRange));
              },
            },
          },
          yAxis: {
            visible: false,
          },
          plotOptions: {
            series: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              },
            },
          },
          tooltip: {
            shared: true,
            padding: 4,
            style: {
              ...fontStyle(9),
            },
            // Custom tooltip positioner that acts like the default one in most cases except for applying special rules
            // when the user is actively selecting a range on the chart.
            positioner: (labelWidth: number, labelHeight: number, point: { plotX: number; plotY: number }) => {
              const chart = chartComponentRef.current?.chart;
              if (!chart) return { x: 0, y: 0 };

              const chartLeft = chart.plotLeft;
              const chartTop = chart.plotTop;
              const chartWidth = chart.chartWidth;
              const chartHeight = chart.chartHeight;

              const margin = 5;

              const baseX = chartLeft + point.plotX;
              const toLeftOfCursor = baseX - margin - labelWidth;
              const toRightOfCursor = baseX + margin;

              const willHitLeftEdge = (position: number) => position < 0;
              const willHitRightEdge = (position: number) => position + labelWidth > chartWidth;

              // Compute the relative selection start position from the global coordinates.
              let selectionStartX: number | null = null;
              if (selectionRef.current !== null) {
                const chartGlobalLeft =
                  (chartComponentRef.current?.container.current?.getBoundingClientRect().x ?? 0) + chartLeft;
                if (selectionRef.current.startX >= chartGlobalLeft) {
                  selectionStartX = selectionRef.current.startX - chartGlobalLeft + chartLeft;
                }
              }

              let x: number;

              // If there isn't any selection or the selection didn't start in the chart then just let the tooltip
              // follow the cursor without going off the edge of the chart.
              if (selectionRef.current === null || selectionStartX === null) {
                if (!willHitLeftEdge(toLeftOfCursor)) {
                  x = toLeftOfCursor;
                } else if (!willHitRightEdge(toRightOfCursor)) {
                  x = toRightOfCursor;
                } else {
                  x = toLeftOfCursor;
                }
              }
              // If there is a selection in the chart then we have to consider more options in order to not cover up the
              // region being selected.
              else {
                const toLeftOfSelection = selectionStartX - margin - labelWidth;
                const toRightOfSelection = selectionStartX + margin;

                // First try to put the tooltip on the far side of the selection.
                if (selectionRef.current?.direction === 'left' && !willHitRightEdge(toRightOfSelection)) {
                  x = toRightOfSelection;
                } else if (selectionRef.current?.direction === 'right' && !willHitLeftEdge(toLeftOfSelection)) {
                  x = toLeftOfSelection;
                }
                // Next try and put the tooltip on the outside of the selection closest to the cursor.
                else if (selectionRef.current?.direction === 'left' && !willHitLeftEdge(toLeftOfCursor)) {
                  x = toLeftOfCursor;
                } else if (selectionRef.current?.direction === 'right' && !willHitRightEdge(toRightOfCursor)) {
                  x = toRightOfCursor;
                }
                // Otherwise just use the same rules as if we didn't have a selection.
                else if (!willHitLeftEdge(toLeftOfCursor)) {
                  x = toLeftOfCursor;
                } else if (!willHitRightEdge(toRightOfCursor)) {
                  x = toRightOfCursor;
                } else {
                  x = toLeftOfCursor;
                }
              }

              const y = point.plotY + chartTop - labelHeight / 2;

              return {
                x: clamp(x, margin, chartWidth - labelWidth - margin),
                y: clamp(y, margin, chartHeight - labelHeight - margin),
              };
            },
          },
          series: [
            // When the chart is boosted it can only render columns as a single pixel wide, so the boostThreshold needs
            // to be set low enough that it helps with performance but high enough that it doesn't kick in when the
            // histogram is zoomed in far enough that the column rendering limitation is noticeable.
            {
              name: 'Observations',
              data: observationData,
              boostThreshold: 200,
            },
            {
              name: 'Labs',
              data: labData,
              boostThreshold: 200,
            },
            {
              name: 'Medications',
              data: medicationData,
              boostThreshold: 200,
            },
            {
              name: 'Procedures',
              data: procedureData,
              boostThreshold: 200,
            },
            {
              name: 'Problems',
              data: problemData,
              boostThreshold: 200,
            },
          ],
          accessibility: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
        }}
        ref={chartComponentRef}
      />
      {isZoomed && (
        <Button
          size='small'
          sx={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }}
          onClick={() => chartComponentRef.current?.chart.zoomOut()}
        >
          Reset
        </Button>
      )}
    </Box>
  );
};

export default Histogram;
