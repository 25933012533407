import React from 'react';
import { PATIENT_JOURNEY_ENTITIES } from '../../../../data/PatientApprovalData';
import { Button, Stack, useTheme } from '@mui/material';
import { EntitySelection } from './PatientJourneyGroup';

const FilterButton: React.FC<{ isSelected: boolean; onClick: () => void }> = ({ isSelected, onClick, children }) => {
  const theme = useTheme();

  return (
    <Button
      size='small'
      variant={isSelected ? 'contained' : 'outlined'}
      onClick={onClick}
      sx={{ minWidth: 0, paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
    >
      {children}
    </Button>
  );
};

const EntityFilterBar: React.FC<{
  selectedEntity: EntitySelection | null;
  onEntitySelected: (entity: EntitySelection) => void;
}> = ({ selectedEntity, onEntitySelected }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <FilterButton isSelected={selectedEntity === 'All'} onClick={() => onEntitySelected('All')}>
        All
      </FilterButton>

      {PATIENT_JOURNEY_ENTITIES.map(e => (
        <FilterButton key={e} isSelected={selectedEntity === e} onClick={() => onEntitySelected(e)}>
          {e}
        </FilterButton>
      ))}
    </Stack>
  );
};

export default EntityFilterBar;
