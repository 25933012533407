import React from 'react';

import DeviceThermostatTwoToneIcon from '@mui/icons-material/DeviceThermostatTwoTone';
import ScienceTwoToneIcon from '@mui/icons-material/ScienceTwoTone';
import MedicationTwoToneIcon from '@mui/icons-material/MedicationTwoTone';

import { PatientApprovalMeasurementDefinitionType } from '../../../../data/PatientApprovalData';
import assertExhaustive from '../../utils/assertExhaustive';
import { CrisisAlertTwoTone, HealingTwoTone } from '@mui/icons-material';

const MeasurementTypeAvater: React.FC<{ type: PatientApprovalMeasurementDefinitionType }> = ({ type }) => {
  const style = {
    marginRight: '0.5em',
  };

  const component =
    type === 'lab' ? (
      <ScienceTwoToneIcon style={style} />
    ) : type === 'observation' ? (
      <DeviceThermostatTwoToneIcon style={style} />
    ) : type === 'medication' ? (
      <MedicationTwoToneIcon style={style} />
    ) : type === 'procedure' ? (
      <HealingTwoTone style={style} />
    ) : type === 'problem' ? (
      <CrisisAlertTwoTone style={style} />
    ) : (
      assertExhaustive(type)
    );

  return component;
};

export default MeasurementTypeAvater;
