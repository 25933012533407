import { appSettings } from 'AppSettings';
import { PatientReviewScore, PatientReview, ReviewStatus } from './PatientReviewData';
import { BaseGet, BaseGetWithParams } from './BaseData';

export const PATIENT_APPROVAL_MEASUREMENT_DEFINITION_TYPES = [
  'lab',
  'observation',
  'medication',
  'procedure',
  'problem',
] as const;
export type PatientApprovalMeasurementDefinitionType = (typeof PATIENT_APPROVAL_MEASUREMENT_DEFINITION_TYPES)[number];

export type PatientApprovalMeasurementRangeType = 'withinIsNormal' | 'aboveIsAbnormal';

export interface PatientApprovalMeasurementBound {
  description: string;
  value: number;
}

export interface PatientApprovalMeasurementDefinition {
  type: PatientApprovalMeasurementDefinitionType;
  codes: string[];
  isTargetStatistic: boolean;
  shortName: string;
  longName?: string;
  description?: string;
  units?: string;
  normallyDistributed?: boolean;
  rangeType?: PatientApprovalMeasurementRangeType;
  normalMins: PatientApprovalMeasurementBound[];
  normalMaxes: PatientApprovalMeasurementBound[];
}

export interface PatientApprovalPatient {
  researchProjectId: string;
  token1: string;
  token2: string;

  yearOfBirth?: number;
  state?: string;
  sampleBiobanks: string[];

  statisticTotalCount: number;

  labTotalCount: number;
  labCuratedTotalCount: number;
  labTotalWithIssuesCount: number;
  labCuratedTotalWithIssuesCount: number;
  labTotalWithoutIssuesCount: number;
  labCuratedTotalWithoutIssuesCount: number;

  obsTotalCount: number;
  obsCuratedTotalCount: number;
  obsTotalWithIssuesCount: number;
  obsCuratedTotalWithIssuesCount: number;
  obsTotalWithoutIssuesCount: number;
  obsCuratedTotalWithoutIssuesCount: number;

  medTotalCount: number;
  medTotalWithIssuesCount: number;
  medTotalWithoutIssuesCount: number;

  procTotalCount: number;
  procTotalWithIssuesCount: number;
  procTotalWithoutIssuesCount: number;

  problemTotalCount: number;
  problemTotalWithIssuesCount: number;
  problemTotalWithoutIssuesCount: number;

  reviewStatusId: ReviewStatus['reviewStatusId'] | null;
  reviewScore: PatientReviewScore | null;
  reviewNotes: string | null;
}

export interface PatientApprovalPatientTest {
  test: string;
  type: PatientApprovalMeasurementDefinitionType;
  codes: string[];
}

export interface PatientApprovalPatientWeeklyCount {
  performedWeek: string;
  type: PatientApprovalMeasurementDefinitionType;
  totalCount: number;
  noIssuesCount: number;
}

export type PatientMeasurementNormality = 'undetermined' | 'normal' | 'abnormal';

export interface PatientApprovalPatientMeasurement {
  type: PatientApprovalMeasurementDefinitionType;
  performedWeek: string;
  test: string;
  codes: string[];
  average: number;
  units: string;
  normality: PatientMeasurementNormality;
  issues: string[];
}

export interface PatientApprovalDiagnosis {
  name: string;
  diagnosisDate: string;
  issues: string[];
}

export const PATIENT_JOURNEY_ENTITIES = ['Problems', 'Observations', 'Medications', 'Procedures', 'Labs'] as const;
export type PatientJourneyEntity = (typeof PATIENT_JOURNEY_ENTITIES)[number];

export interface PatientJourneyRow {
  id: string;
  date: string;
  entity: PatientJourneyEntity;
  vocabulary: string | null;
  vocabularyCode: string | null;
  name: string;
  result: string | null;
  issues: string[];
}

export interface PatientApprovalPatientData {
  tests: PatientApprovalPatientTest[];
  weeklyCounts: PatientApprovalPatientWeeklyCount[];
  measurements: PatientApprovalPatientMeasurement[];
  diagnoses: PatientApprovalDiagnosis[];
  patientJourney: PatientJourneyRow[];
  review: PatientReview | null;
}

export async function GetResearchProjectMeasurementDefinitions(
  researchProjectId: string,
  accessToken: string | undefined
): Promise<PatientApprovalMeasurementDefinition[]> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v1/PatientApproval/ResearchProject/${researchProjectId}/MeasurementDefinitions`,
    accessToken
  );
}

export async function GetResearchProjectApprovalPatients(
  researchProjectId: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatient[] | undefined> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v1/PatientApproval/ResearchProject/${researchProjectId}/Patients`,
    accessToken
  );
}

export async function GetResearchProjectPatientData(
  researchProjectId: string,
  token1: string,
  token2: string,
  accessToken: string | undefined
): Promise<PatientApprovalPatientData | undefined> {
  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v1/PatientApproval/ResearchProject/${researchProjectId}/Patient`,
    new URLSearchParams({ token1, token2 }),
    accessToken
  );
}
