import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';

import MeasurementTypeAvatar from '../avatars/MeasurementTypeAvatar';
import { useMeasurement, useMeasurementDefinitions, useSelectMeasurement } from '../../stores/dataStore';
import { ErrorTwoTone } from '@mui/icons-material';

const MeasurementSelector: React.FC = () => {
  const theme = useTheme();

  const { loading, data: measurements, error: measurementsError } = useMeasurementDefinitions();

  const selectedMeasurement = useMeasurement();
  const selectMeasurement = useSelectMeasurement();

  if (measurementsError) {
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        spacing={1}
        height={56}
        sx={{ paddingX: theme.spacing(2) }}
      >
        <ErrorTwoTone color='error' fontSize='medium' />
        <Typography color={theme.palette.error.main} textAlign='center'>
          {measurementsError}
        </Typography>
      </Stack>
    );
  }

  if (loading || !measurements || !selectedMeasurement) {
    return <Skeleton animation='wave' variant='rounded' height={56} />;
  }

  const onSelect = (selectedName: string) => {
    const measurement = measurements.find(m => m.shortName === selectedName);
    if (measurement) {
      selectMeasurement(measurement);
    }
  };

  const changeMeasurement = (type: 'prev' | 'next') => {
    const curIdx = measurements.findIndex(m => m.shortName === selectedMeasurement.shortName);
    const offset = type === 'prev' ? -1 : 1;

    let newIdx = curIdx + offset;
    if (newIdx < 0) {
      newIdx += measurements.length;
    } else if (newIdx >= measurements.length) {
      newIdx -= measurements.length;
    }

    onSelect(measurements[newIdx].shortName);
  };

  return (
    <Stack direction='row' spacing={2}>
      <FormControl fullWidth>
        <InputLabel id='measurement-select-label'>Measurement</InputLabel>
        <Select
          displayEmpty
          labelId='measurement-select-label'
          id='measurement-select'
          value={selectedMeasurement.shortName}
          variant='standard'
          onChange={event => onSelect(event.target.value)}
          disabled={measurements?.length === 0}
          sx={{ marginTop: `${theme.spacing(0.75)} !important` }}
        >
          {measurements.map((measurement, idx) => (
            <MenuItem key={idx} value={measurement.shortName}>
              <Stack direction='row' alignItems='center'>
                <MeasurementTypeAvatar type={measurement.type} />
                <Box style={{ paddingRight: theme.spacing(1) }}>{measurement.shortName}</Box>
                <Box
                  style={{ fontWeight: 'bolder', paddingRight: theme.spacing(1) }}
                  dangerouslySetInnerHTML={{ __html: measurement.longName ?? '' }}
                />
                <Box>{measurement.units ? `(${measurement.units})` : ''}</Box>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ButtonGroup variant='text' size='small' disabled={loading}>
        <Button onClick={() => changeMeasurement('prev')} disabled={measurements?.length === 0}>
          <ArrowDropUpTwoToneIcon />
          Prev
        </Button>
        <Button onClick={() => changeMeasurement('next')} disabled={measurements?.length === 0}>
          Next <ArrowDropDownTwoToneIcon />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default MeasurementSelector;
