import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import useAuth from '../../auth/UseAuth';
import {
  GetPipelineExecutionHistory,
  SuperDuperFiestaExecutedPipelineHistory,
  SuperDuperFiestaPipeline,
} from '../../data/SuperDuperFiestaData';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import {
  executedAt,
  executedPipelineHistoryId,
  executionDurationMs,
  pipeline,
  pipelineId,
  queryHistory,
  state,
} from '../../util/Constants';
import { find, map } from 'lodash';
import { dateGetter, formatMsToHHMMSS } from '../../util/grid/TableUtils';
import useSuperDuperFiestaPipelines from '../../components/hooks/UseSuperDuperFiestaPipelines';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PipelineQueryHistoryDialog } from './PipelineQueryHistoryDialog';
import { FlexBox } from '../../components/FlexBox';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { ActionButton } from '../../components/DialogOpenButton';

export const PipelineHistoryGrid = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = loadingProps;
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const pipelines = useSuperDuperFiestaPipelines();
  const columns = useColumns(pipelines);
  const [rows, setRows] = useState<ReadonlyArray<SuperDuperFiestaExecutedPipelineHistory>>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      const data = await GetPipelineExecutionHistory(accessToken);
      setRows(map(data, (row, index) => ({ ...row, id: index })));
    });
  }, [accessToken, setLoadingState, refreshTrigger]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingState(prev => ({ ...prev, disableLoadingIndicator: true }));
      setRefreshTrigger(prev => !prev);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [setLoadingState]);

  return (
    <FlexTableBox sx={{ flexDirection: 'column', gap: 1 }}>
      <FlexBox sx={{ flexDirection: 'row-reverse' }}>
        <ActionButton
          onClick={() => setRefreshTrigger(prev => !prev)}
          title={loadingState.status === 'Loading' ? t('refreshing') : t('refresh')}
        >
          <RefreshOutlinedIcon />
        </ActionButton>
      </FlexBox>
      <FlexTableBox>
        <CompactGridWrapper
          columns={columns}
          rows={rows}
          enableCustomAutoColumnResizing={true}
          initialState={{
            sorting: {
              sortModel: [{ field: executedAt, sort: 'desc' }],
            },
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};

const useColumns = (pipelines: ReadonlyArray<SuperDuperFiestaPipeline>): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: executedAt,
        headerName: t(executedAt),
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: pipelineId,
        headerName: t(pipeline),
        width: 300,
        valueFormatter: ({ value }) => value && find(pipelines, i => i.pipelineId === value)?.name,
      },
      {
        field: executionDurationMs,
        headerName: t('executionDuration'),
        valueGetter: params =>
          // + 'Z' is a hack to tell JS date is in UTC
          params.row.executionDurationMs ?? new Date().getTime() - new Date(params.row.executedAt + 'Z').getTime(),
        valueFormatter: ({ value }) => {
          return `${formatMsToHHMMSS(value)} -- (${(value as number).toLocaleString()} ms)`;
        },
        width: 300,
      },
      {
        field: state,
        headerName: t(state),
        width: 200,
      },
      {
        field: executedPipelineHistoryId,
        headerName: t(executedPipelineHistoryId),
        width: 150,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: queryHistory,
        headerName: t(queryHistory),
        renderCell: params =>
          params?.row?.executedPipelineHistoryId && (
            <PipelineQueryHistoryDialog executedPipelineHistoryId={params?.row?.executedPipelineHistoryId} />
          ),
      },
    ];
  }, [t, pipelines]);
};
