import { GRID_TREE_DATA_GROUPING_FIELD, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetSampleTrackingCountsBySampleType,
  SampleTrackingCountRow,
  SampleTrackingCounts,
  TransitionType,
} from '../../data/SampleTrackingData';
import { GridCellCallBack } from '../../components/grid/GridCellCallback';
import {
  SampleTrackingSelectedTransitionSampleModal,
  SampleTrackingTransitionSampleModal,
} from './SampleTrackingTransitionSampleModal';
import { generateWidthFromProperty } from '../../util/grid/TableUtils';
import { all, sampleGroup, selectedSampleCount } from '../../util/Constants';
import { LoadingState, LoadState } from '../../components/LoadingStateUtil';
import { CaseInsensitiveMap, createCaseInsensitiveMap, UseStateSetter } from '../../util/TypeUtil';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { Box, Typography } from '@mui/material';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { TransitionTableHeaderIcon } from '../../util/TransitionUtil';
import { orderBy } from 'lodash';
import { SampleTrackingPathConfigurationModal } from './SampleTrackingPathConfigurationModal';
import { FlexBox } from '../../components/FlexBox';
import { LightTooltip } from '../../components/grid/cell/GridCellTooltip';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import { useConfiguredTransitions } from '../../components/hooks/UseConfiguredTransitions';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';

export interface SampleTrackingGridProps {
  researchProjectId: string;
}

export type TransitionTypeExtended = TransitionType | 'Selected';

interface TransitionRecordModalProps {
  open: boolean;
  title?: string;
  transitionType?: TransitionTypeExtended;
  sampleGroup?: string;
  configuredTransitionId?: string;
}

type Row = {
  id: number;
  sampleGroup: string;
  hierarchy: string[];
  sampleCounts: CaseInsensitiveMap<string, SampleTrackingCountRow>;
  patientCounts: CaseInsensitiveMap<string, SampleTrackingCountRow>;
};

export const SampleTrackingGrid = ({ researchProjectId }: SampleTrackingGridProps) => {
  const { accessToken } = useAuth();
  const { t } = useMemoTranslation();

  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const [transitionRecordModalProps, setTransitionRecordModalProps] = useState<TransitionRecordModalProps>({
    open: false,
  });

  const [data, setData] = useState<ReadonlyArray<SampleTrackingCounts>>([]);
  const [configuredTransitions, configuredTransitionsRefreshTrigger] = useConfiguredTransitions(
    researchProjectId,
    setLoadingState
  );

  const columns = useColumns(setTransitionRecordModalProps, configuredTransitions);
  const rows = useRows(data);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!(accessToken && researchProjectId !== undefined)) {
        return;
      }

      let rows = await GetSampleTrackingCountsBySampleType(researchProjectId, accessToken);

      setData(rows);
    });
  }, [accessToken, researchProjectId, refreshTrigger, configuredTransitions]);

  return (
    <>
      <>
        <Box display='flex' flexDirection='row' alignItems='center' mb={1} justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('sampleTrackingGridInfo')}</Typography>
          <AuthorizedSection hasSampleTrackingWriteAccess>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <SampleTrackingPathConfigurationModal
                onClose={hasChanges => {
                  if (hasChanges) {
                    configuredTransitionsRefreshTrigger();
                  }
                }}
              />
            </Box>
          </AuthorizedSection>
        </Box>
        <CompactGridWrapper
          loadingState={loadingState}
          rows={rows}
          columns={columns}
          disableColumnFilter={true}
          disableColumnMenu={true}
          initialState={{
            pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
            sorting: {
              sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }],
            },
          }}
          density='compact'
          hideFooterSelectedRowCount={true}
          getTreeDataPath={row => row?.hierarchy}
          treeData
          groupingColDef={{
            headerAlign: 'center',
            align: 'center',
            headerName: t(sampleGroup),
            width: generateWidthFromProperty(data ?? [], 150, (row: SampleTrackingCounts) => row.sampleGroup),
          }}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
        />
        {transitionRecordModalProps.open && (
          <>
            {transitionRecordModalProps.transitionType === 'Selected' ? (
              <SampleTrackingSelectedTransitionSampleModal
                researchProjectId={researchProjectId}
                title={transitionRecordModalProps.title ?? ''}
                sampleGroup={transitionRecordModalProps.sampleGroup}
                transitionType={transitionRecordModalProps.transitionType}
                onClose={() => {
                  setTransitionRecordModalProps({ open: false });
                  setRefreshTrigger(currentState => !currentState);
                }}
              />
            ) : (
              <SampleTrackingTransitionSampleModal
                researchProjectId={researchProjectId}
                title={transitionRecordModalProps.title ?? ''}
                sampleGroup={transitionRecordModalProps.sampleGroup}
                transitionType={transitionRecordModalProps.transitionType}
                configuredTransitionId={transitionRecordModalProps.configuredTransitionId ?? ''}
                onClose={() => {
                  setTransitionRecordModalProps({ open: false });
                  setRefreshTrigger(currentState => !currentState);
                }}
              />
            )}
          </>
        )}
      </>
      <Typography variant={'subtitle1'} mt={2}>
        {t('countByLegend')}
      </Typography>
    </>
  );
};

const useRows = (sampleTrackingBySampleTypes: ReadonlyArray<SampleTrackingCounts>) => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    let index = 0;

    const rows: Row[] = sampleTrackingBySampleTypes.map(row => {
      return {
        id: index++,
        hierarchy: row.sampleGroup?.toLowerCase() === 'all' ? [t(all)] : [t(all), row.sampleGroup],
        patientCounts: createCaseInsensitiveMap(row.patientCounts),
        sampleCounts: createCaseInsensitiveMap(row.sampleCounts),
        sampleGroup: row.sampleGroup,
      };
    });

    return rows;
  }, [t, sampleTrackingBySampleTypes]);
};

const useColumns = (
  setTransitionRecordModalProps: UseStateSetter<TransitionRecordModalProps>,
  configuredTransitions: ReadonlyArray<ConfiguredTransition>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  const gridCellRendererHelper = useCallback(
    (transitionType: TransitionTypeExtended, configuredTransitionId: string, displayName: string, text?: string) => {
      return (params: GridRenderCellParams) => {
        const sampleCounts = params.row?.sampleCounts?.get(displayName) ?? 0;
        const patientCounts = params.row?.patientCounts?.get(displayName) ?? 0;

        const sampleGroup = params.row?.sampleGroup === all ? undefined : params.row?.sampleGroup;

        const items =
          transitionType === 'Selected'
            ? [
                {
                  label: 'S:',
                  fullLabel: t('selected'),
                  count: `${sampleCounts?.remainingCount ?? 0} (${patientCounts?.remainingCount ?? 0}p)`,
                },
              ]
            : [
                {
                  label: 'N:',
                  fullLabel: t('needsCheckBy'),
                  count: `${sampleCounts?.workLeftCount ?? 0}`,
                },
                {
                  label: 'P:',
                  fullLabel: t('passed'),
                  count: `${sampleCounts?.passedCount ?? 0}`,
                },
                {
                  label: 'F:',
                  fullLabel: t('failed'),
                  count: `${sampleCounts?.failedCount ?? 0}`,
                },
                {
                  label: 'R:',
                  fullLabel: t('remaining'),
                  count: `${sampleCounts?.remainingCount ?? 0}`,
                },
              ];

        return (
          <FlexBox flexDirection={'column'} padding={1}>
            {items.map((item, i) => (
              <FlexBox sx={{ gap: 0.5, alignItems: 'center' }} key={i}>
                <Typography variant={'h6'} sx={{ fontSize: '12px', fontWeight: 'bold', width: '30%' }}>
                  <LightTooltip title={item.fullLabel}>
                    <span>{item.label}</span>
                  </LightTooltip>
                </Typography>
                <GridCellCallBack
                  text={item.count === '0' || item.count === '0 (0p)' ? '--' : item.count}
                  textStyle={{ fontWeight: 'bold' }}
                  callBack={() =>
                    setTransitionRecordModalProps({
                      open: true,
                      title: `'${displayName}' Samples (${sampleGroup ?? t(all)})`,
                      transitionType: transitionType,
                      sampleGroup: sampleGroup,
                      configuredTransitionId: configuredTransitionId,
                    })
                  }
                  sx={{ padding: 0 }}
                />
              </FlexBox>
            ))}
          </FlexBox>
        );
      };
    },
    [t, setTransitionRecordModalProps]
  );

  return useMemo(
    () => [
      {
        field: selectedSampleCount,
        renderHeader: () => <TransitionTableHeaderIcon transitionType={'Selected'} header={t(selectedSampleCount)} />,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        maxWidth: 300,
        flex: 1,
        renderCell: gridCellRendererHelper('Selected', 'idk', 'Selected'),
      },
      ...orderBy(configuredTransitions, o => o.transitionOrder).map((o): GridColDef => {
        const transitionType = o.transitionEnum.name as TransitionType;

        return {
          field: o.displayName,
          headerName: o.displayName,
          renderHeader: () => <TransitionTableHeaderIcon transitionType={transitionType} header={o.displayName} />,
          headerAlign: 'center',
          align: 'center',
          minWidth: 100,
          maxWidth: 300,
          flex: 1,

          renderCell: gridCellRendererHelper(transitionType, o.configuredTransitionId, o.displayName),
        };
      }),
    ],
    [t, gridCellRendererHelper, configuredTransitions]
  );
};
