import { appSettings } from 'AppSettings';
import { LlCustomer } from 'data/LlCustomerData';
import { ItemOfResearchType } from './ResearchProjectPatientData';
import { BaseGet } from './BaseData';
import { Dictionary } from 'lodash';

export interface ResearchProjectDetails {
  researchProjectId: string;
  name: string;
  customer: LlCustomer;
  expectedCohortSize?: number;
  actualCohortSize?: number;
  status: string;
  salesforceLink?: string;
  mondayLink?: string;
}

export interface ResearchProject {
  researchProjectId: string;
  name: string;
  customerId: string;
  description?: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  expectedCohortSize?: number;
  salesforceLink?: string;
  mondayLink?: string;
}

export interface ItemOfResearch {
  id: string;
  name: string;
  type: ItemOfResearchType;
}

export async function GetResearchProjectDetails(
  researchProjectId: string,
  accessToken: string
): Promise<ResearchProjectDetails | undefined> {
  if (accessToken) {
    try {
      const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects/Details/${researchProjectId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const researchProject: ResearchProjectDetails = await response.json();
        return researchProject;
      }
    } catch (err) {
      console.dir(err);
    }
  }
  return undefined;
}

export async function GetAllResearchProjectsDetails(
  accessToken?: string
): Promise<ReadonlyArray<ResearchProjectDetails>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ResearchProjects/Details`, accessToken);
}

export async function GetAllResearchProjects(accessToken?: string): Promise<ReadonlyArray<ResearchProject>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ResearchProjects`, accessToken);
}

export async function GetResearchProject(researchProjectId: string, accessToken?: string): Promise<ResearchProject> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ResearchProjects/${researchProjectId}`, accessToken);
}

export async function GetResearchProjectActualCohortCounts(accessToken?: string): Promise<Dictionary<number>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ResearchProjects/ActualCohortCounts`, accessToken);
}
