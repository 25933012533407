import React, { useEffect, useMemo, useState } from 'react';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import {
  createdAt,
  createdBy,
  dataClassName,
  dataSourceName,
  entityName,
  executedAt,
  executionOrder,
  executionDurationMs,
  field,
  issueName,
  nameField,
  needsReview,
  queryCondition,
  queryGroupBy,
  queryJoin,
  rowsAffected,
  state,
  stepTypeName,
  value,
} from '../../util/Constants';
import { FlexBox } from 'components/FlexBox';
import { FlexTableBox } from 'components/FlexTableBox';
import { GridExportButton } from 'components/GridExportButton';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { dateGetter, formatMsToHHMMSS } from '../../util/grid/TableUtils';
import { GetPipelineRules } from '../../data/SuperDuperFiestaData';
import { GridColDef, GridPagination, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import {
  PipelineRulesConfig,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from 'data/SuperDuperFiestaData';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

export const PipelineRulesGrid = ({
  loadingProps,
  selectedEntity,
  selectedPipeline,
  selectedStage,
}: {
  loadingProps: LoadingProps;
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
}) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();

  const [, setLoadingState] = loadingProps;

  const columns = useColumns();
  const [rows, setRows] = useState<ReadonlyArray<object>>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      if (selectedEntity !== null && selectedPipeline !== null && selectedStage !== null) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
          entityId: selectedEntity.entityId,
        };

        const data = await GetPipelineRules(queryParams, accessToken);

        if (data.length === 0) {
          setRows([]);
        } else {
          setRows(
            data.map((row, index) => ({
              ...row,
              id: row?.stepUpdateValueId || index,
              executionOrder: index + 1,
              hierarchy: row?.hierarchy !== undefined && row.hierarchy.length !== 0 ? row.hierarchy : [index],
            }))
          );
        }
      } else {
        setRows([]);
      }
    });
  }, [setLoadingState, accessToken, selectedPipeline, selectedStage, selectedEntity]);

  function CustomToolbar() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{ mt: 1 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Select a pipeline
      </Box>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <FlexBox flexDirection={'row-reverse'}>
          <GridExportButton apiRef={apiRef} fileName='pipeline_rules' />
        </FlexBox>
        <CompactGridWrapper
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowHeight={25}
          pagination
          pageSizeOptions={[100, 250, 500]}
          slots={{
            footer: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          treeData
          getTreeDataPath={row => row?.hierarchy}
          groupingColDef={{
            headerName: t(executionOrder),
            width: 125,
            valueFormatter: params => apiRef.current.getRow(params.id!)?.executionOrder,
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: nameField,
        headerName: t(nameField),
        width: 250,
        renderCell: (params: any) => (
          <Tooltip title={params.row.description || params.value.toString()}>
            <span className='table-cell-trucate'>{params.value.toString()}</span>
          </Tooltip>
        ),
      },
      {
        field: stepTypeName,
        headerName: t(stepTypeName),
        width: 150,
      },
      {
        field: entityName,
        headerName: t(entityName),
        width: 150,
      },
      {
        field: dataSourceName,
        headerName: t(dataSourceName),
        width: 150,
      },
      {
        field: dataClassName,
        headerName: t(dataClassName),
        width: 150,
      },
      {
        field: issueName,
        headerName: t(issueName),
        width: 150,
      },
      {
        field: needsReview,
        headerName: t(needsReview),
        width: 100,
        renderCell: renderCellBooleanCheck,
      },
      {
        field: queryCondition,
        headerName: t(queryCondition),
        width: 250,
      },
      {
        field: queryJoin,
        headerName: t(queryJoin),
        width: 250,
      },
      {
        field: queryGroupBy,
        headerName: t(queryGroupBy),
        width: 150,
      },
      {
        field: field,
        headerName: t(field),
        width: 100,
      },
      {
        field: value,
        headerName: t(value),
        width: 100,
      },
      {
        field: createdBy,
        headerName: t(createdBy),
        width: 100,
      },
      {
        field: createdAt,
        headerName: t(createdAt),
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: rowsAffected,
        headerName: t(rowsAffected),
        width: 100,
      },
      {
        field: executionDurationMs,
        headerName: t('executionDuration'),
        valueGetter: params =>
          // + 'Z' is a hack to tell JS date is in UTC
          params.row.executionDurationMs ?? new Date().getTime() - new Date(params.row.executedAt + 'Z').getTime(),
        valueFormatter: ({ value }) => {
          return `${formatMsToHHMMSS(value)} -- (${(value as number).toLocaleString()} ms)`;
        },
        width: 200,
      },
      {
        field: state,
        headerName: t(state),
        width: 100,
      },
      {
        field: executedAt,
        headerName: 'Last Executed At',
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
    ];
  }, [t]);
};
