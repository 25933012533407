import {
  CreateQuantificationQualityCheck,
  IdMappingErrorMessageType,
  ParentExhaustion,
  SampleTrackingCheckBy,
  SampleTrackingIdMapping,
  SampleTrackingQuantificationCheckByDto,
} from '../../data/SampleTrackingData';
import {
  checkByFailedReason,
  concentration,
  labAssignedSampleId,
  positionId,
  qualityCheckStatus,
  r1FastQLocation,
  r2FastQLocation,
  rackId,
  sampleIdentifier,
  sampleVolume,
  twoSixtyTwoEightyRatio,
  yieldField,
} from '../../util/Constants';
import { Dictionary } from 'lodash';
import { CheckByFailedReason } from '../../data/ReferenceData';
import { v4 as uuid } from 'uuid';
import { ConfiguredTransition } from '../../data/ConfiguredTransitionData';

type CustomHandleFunc = (
  researchProjectId: string,
  configuredTransitionId: string,
  idMappings: ReadonlyArray<SampleTrackingCheckBy>,
  parentExhaustion: ParentExhaustion,
  accessToken: string
) => Promise<string>;

type GetErrorMessagesFunc = (
  row: SampleTrackingIdMapping,
  config: CheckByConfiguration,
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>
) => IdMappingErrorMessageType[];

type ConfigurationType = 'Normal' | 'Sequencing' | 'Quantification';

export interface CheckByConfiguration {
  inputTextKey: string;
  maxParsedValues: number;
  rowBuilder: (row: string[], index: number, config: CheckByConfiguration) => SampleTrackingIdMapping;
  requireLabAssignedId: boolean;
  configurationType: ConfigurationType;
  supportsCheckByFailedReason: boolean;
  columns?: string[];
  customHandle?: CustomHandleFunc;
  getErrorMessages?: GetErrorMessagesFunc;
}

export function BuildCheckByConfiguration(
  configuredTransition: ConfiguredTransition,
  t: (val: string) => string
): CheckByConfiguration {
  let configurationType: ConfigurationType = 'Normal';
  const columns = [sampleIdentifier];

  if (
    ['supportsOutputSamples', 'requiresOutputSamples', 'relabels'].includes(configuredTransition.sampleActionOnCheckBy)
  ) {
    columns.push(labAssignedSampleId);
  }

  columns.push(qualityCheckStatus);

  if (configuredTransition.enableCheckByFailedReason) {
    columns.push(checkByFailedReason);
  }

  if (configuredTransition.enableSampleVolumeOnCheckBy) {
    columns.push(sampleVolume);
  }

  if (configuredTransition.transitionEnum?.name === 'Sequencing') {
    columns.push(r1FastQLocation, r2FastQLocation);
    configurationType = 'Sequencing';
  } else if (configuredTransition.transitionEnum?.name === 'Quantification') {
    columns.push(rackId, positionId, yieldField, concentration, twoSixtyTwoEightyRatio);
    configurationType = 'Quantification';
  }

  let requireLabAssignedId = false;

  if (['requiresOutputSamples'].includes(configuredTransition.sampleActionOnCheckBy)) {
    requireLabAssignedId = true;
  }

  let customHandleFunc: CustomHandleFunc | undefined = undefined;
  let getErrorMessagesFunc: GetErrorMessagesFunc | undefined = undefined;

  if (configuredTransition.transitionEnum?.name === 'Quantification') {
    customHandleFunc = async (
      researchProjectId: string,
      configuredTransitionId: string,
      idMappings: ReadonlyArray<SampleTrackingCheckBy>,
      parentExhaustion: ParentExhaustion,
      accessToken: string
    ) => {
      const payload: SampleTrackingQuantificationCheckByDto = {
        sampleTrackingCheckByDtos: idMappings,
        sampleTrackingQuantificationDetailsCheckByDtos: idMappings.map(i => {
          i.lookupId = uuid();

          if (i.dynamicData === undefined) {
            return { sampleId: i.originalSampleId };
          }

          return {
            lookupId: i.lookupId,
            sampleId: i.originalSampleId,
            rackId: i.dynamicData[rackId],
            positionId: i.dynamicData[positionId],
            yield: i.dynamicData[yieldField] ? +i.dynamicData[yieldField] : undefined,
            concentration: i.dynamicData[concentration] ? +i.dynamicData[concentration] : undefined,
            sampleVolume: i.dynamicData[sampleVolume] ? +i.dynamicData[sampleVolume] : undefined,
            twoSixtyTwoEightyRatio: i.dynamicData[twoSixtyTwoEightyRatio]
              ? +i.dynamicData[twoSixtyTwoEightyRatio]
              : undefined,
          };
        }),
      };

      return await CreateQuantificationQualityCheck(researchProjectId, configuredTransitionId, payload, accessToken);
    };

    getErrorMessagesFunc = (row: SampleTrackingIdMapping) => {
      if (row.dynamicData === undefined) {
        return [];
      }

      const errorMessages: IdMappingErrorMessageType[] = [];

      const yieldValue = row.dynamicData[yieldField];
      const concentrationValue = row.dynamicData[concentration];
      const twoSixtyTwoEightyRatioValue = row.dynamicData[twoSixtyTwoEightyRatio];

      if (yieldValue && isNaN(+yieldValue)) {
        errorMessages.push('yieldIsNan');
      }

      if (concentrationValue && isNaN(+concentrationValue)) {
        errorMessages.push('concentrationIsNan');
      }

      if (twoSixtyTwoEightyRatioValue && isNaN(+twoSixtyTwoEightyRatioValue)) {
        errorMessages.push('twoSixtyTwoEightyIsNan');
      }

      return errorMessages;
    };
  }

  return {
    supportsCheckByFailedReason: configuredTransition.enableCheckByFailedReason,
    configurationType: configurationType,
    requireLabAssignedId: requireLabAssignedId,
    inputTextKey: columns.map(i => t(i)).join(','),
    maxParsedValues: columns.length,
    rowBuilder: (row: string[], index: number, config: CheckByConfiguration): SampleTrackingIdMapping => {
      const dynamicData: Dictionary<string | undefined> = {};

      config.columns?.forEach(
        (column, index) => (dynamicData[column] = row.length > index ? row[index] || undefined : undefined)
      );

      return {
        index: index.toString(),
        sampleIdentifier: '',
        qualityCheckStatus: '',
        ...dynamicData,
        sampleVolume: dynamicData[sampleVolume] ? (+dynamicData[sampleVolume] as number) : undefined,
        dynamicData: dynamicData,
      };
    },
    columns: columns,
    customHandle: customHandleFunc,
    getErrorMessages: getErrorMessagesFunc,
  };
}
