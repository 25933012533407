import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import {
  entityName,
  executedAt,
  executedQueryHistoryId,
  executionDurationMs,
  query,
  rowsAffected,
  state,
  stepId,
  stepName,
} from '../../util/Constants';
import {
  GetQueryExecutionHistory,
  SuperDuperFiestaEntity,
  SuperDuperFiestaExecutedQueryHistory,
  SuperDuperFiestaStep,
} from '../../data/SuperDuperFiestaData';
import { GridColDef } from '@mui/x-data-grid-pro';
import { dateGetter, formatMsToHHMMSS } from '../../util/grid/TableUtils';
import { map } from 'lodash';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import { FlexBox } from '../../components/FlexBox';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { ActionButton } from '../../components/DialogOpenButton';
import useSuperDuperFiestaSteps from '../../components/hooks/UseSuperDuperFiestaSteps';
import useSuperDuperFiestaEntities from '../../components/hooks/UseSuperDuperFiestaEntities';

export const PipelineQueryHistoryGrid = ({
  loadingProps,
  executedPipelineHistoryId,
}: {
  loadingProps: LoadingProps;
  executedPipelineHistoryId: string;
}) => {
  const { t } = useMemoTranslation();
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = loadingProps;
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const steps = useSuperDuperFiestaSteps();
  const entities = useSuperDuperFiestaEntities();

  const columns = useColumns(entities, steps);
  const [rows, setRows] = useState<ReadonlyArray<SuperDuperFiestaExecutedQueryHistory>>([]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken || !executedPipelineHistoryId) {
        return;
      }

      const data = await GetQueryExecutionHistory(executedPipelineHistoryId, accessToken);
      setRows(map(data, (row, index) => ({ ...row, id: index })));
    });
  }, [accessToken, setLoadingState, executedPipelineHistoryId, refreshTrigger]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingState(prev => ({ ...prev, disableLoadingIndicator: true }));
      setRefreshTrigger(prev => !prev);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [setLoadingState]);

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexBox sx={{ flexDirection: 'row-reverse' }}>
        <ActionButton
          onClick={() => setRefreshTrigger(prev => !prev)}
          title={loadingState.status === 'Loading' ? t('refreshing') : t('refresh')}
        >
          <RefreshOutlinedIcon />
        </ActionButton>
      </FlexBox>
      <FlexTableBox>
        <CompactGridWrapper
          columns={columns}
          rows={rows}
          enableCustomAutoColumnResizing={true}
          initialState={{
            sorting: {
              sortModel: [{ field: executedAt, sort: 'desc' }],
            },
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};
const useColumns = (
  entities: ReadonlyArray<SuperDuperFiestaEntity>,
  steps: ReadonlyArray<SuperDuperFiestaStep>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: executedAt,
        headerName: t(executedAt),
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: entityName,
        headerName: t(entityName),
        width: 150,
        valueGetter: params => {
          const step = steps.find(step => step.stepId === params.row.stepId);
          const entity = entities.find(entity => entity.entityId === step?.entityId);
          return entity?.name;
        },
      },
      {
        field: executionDurationMs,
        headerName: t('executionDuration'),
        valueGetter: params =>
          // + 'Z' is a hack to tell JS date is in UTC
          params.row.executionDurationMs ?? new Date().getTime() - new Date(params.row.executedAt + 'Z').getTime(),
        valueFormatter: ({ value }) =>
          value && `${formatMsToHHMMSS(value)} -- (${(value as number).toLocaleString()} ms)`,
        width: 300,
      },
      {
        field: state,
        headerName: t(state),
        width: 100,
      },
      {
        field: query,
        headerName: t(query),
        width: 400,
      },
      {
        field: rowsAffected,
        headerName: t(rowsAffected),
        width: 100,
        valueFormatter: ({ value }) => value && (value as number).toLocaleString(),
      },
      {
        field: stepName,
        headerName: t(stepName),
        width: 150,
        valueGetter: params => {
          const step = steps.find(step => step.stepId === params.row.stepId);
          return step?.name;
        },
      },
      {
        field: executedQueryHistoryId,
        headerName: t(executedQueryHistoryId),
        width: 150,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: stepId,
        headerName: t(stepId),
        width: 150,
        renderCell: renderGuidCellTooltip,
      },
    ];
  }, [t, steps, entities]);
};
