import { Button, CircularProgress, useTheme } from '@mui/material';
import { PatientCountType } from 'components/grid/GridCountType';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DiseaseArea } from 'data/DiseaseAreaData';

export interface PatientCountGridCellProps {
  diseaseArea: DiseaseArea;
  biobankId?: string;
  sampleTypeId?: string;
  count: number;
  countType: PatientCountType;
  pendingRefresh: boolean;
  onCountClick(
    count: number,
    diseaseArea: DiseaseArea,
    patientCountType: PatientCountType,
    biobankId?: string,
    sampleTypeId?: string
  ): void;
}

export const ERROR_COUNT = -1;

export const PatientCountGridCell = ({
  diseaseArea,
  biobankId,
  sampleTypeId,
  count,
  countType,
  pendingRefresh,
  onCountClick,
}: PatientCountGridCellProps) => {
  const theme = useTheme();

  const handleCountButtonClick = async () => {
    onCountClick(count, diseaseArea, countType, biobankId, sampleTypeId);
  };

  return (
    <Button
      disabled={count <= 0 || pendingRefresh}
      onClick={handleCountButtonClick}
      sx={[
        {
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: `${theme.colors.primary.dark}`,
          },
        },
      ]}
    >
      {pendingRefresh && <CircularProgress data-testid={'loading-indicator'} size={10} />}
      {count === ERROR_COUNT && <ErrorOutlineIcon />}
      {count === ERROR_COUNT || pendingRefresh ? '' : count.toLocaleString() ?? 0}
    </Button>
  );
};
