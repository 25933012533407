import {
  GetResearchProjectMeasurementDefinitions,
  PatientApprovalMeasurementDefinition,
} from '../../../../data/PatientApprovalData';
import { StateCreator } from 'zustand';
import { AccessTokenSlice } from './accessTokenSlice';
import { initialLoadableData, LoadableData, loadedData, trackLoading } from '../../utils/loadingUtils';

export interface MeasurementDefinitionsSlice {
  measurementDefinitions: LoadableData<PatientApprovalMeasurementDefinition[]>;
  setMeasurementDefinitions: (measurementDefinitions: PatientApprovalMeasurementDefinition[] | undefined) => void;
  loadMeasurementDefinitionsFromServer: (researchProjectId: string) => void;
}

export const createMeasurementDefinitionsSlice: StateCreator<
  AccessTokenSlice & MeasurementDefinitionsSlice,
  [],
  [],
  MeasurementDefinitionsSlice
> = (set, get) => ({
  measurementDefinitions: initialLoadableData(),
  setMeasurementDefinitions: measurementDefinitions =>
    set({ measurementDefinitions: loadedData(measurementDefinitions) }),
  loadMeasurementDefinitionsFromServer: researchProjectId => {
    const accessToken = get().accessToken;

    if (accessToken === undefined) {
      return;
    }

    trackLoading(
      () => GetResearchProjectMeasurementDefinitions(researchProjectId, accessToken),
      state => set({ measurementDefinitions: state })
    );
  },
});
