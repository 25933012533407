import React, { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { Page } from 'components/Page';
import useAuth from 'auth/UseAuth';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { AuthorizedSection } from 'auth/AuthorizedSection';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { description, installedOn, version } from '../util/Constants';
import { CompactGridWrapper } from '../components/grid/CompactGridWrapper';
import { PageContent } from '../components/PageContent';
import { dateGetter } from '../util/grid/TableUtils';
import { FlexBox } from '../components/FlexBox';
import { FlexTableBox } from '../components/FlexTableBox';
import { Box, Divider, Typography } from '@mui/material';
import { ErrorManagement, LoadingState, LoadState } from '../components/LoadingStateUtil';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { ExecuteMigrations, GetMigrations, RepairMigrations } from '../data/SuperDuperFiestaData';
import { Migration } from '../data/MigrationsData';

export const SuperDuperFiestaMigrationsPage = () => {
  const { t } = useMemoTranslation();
  const [migrations, setMigrations] = useState<ReadonlyArray<Migration>>([]);
  const [tbdMigrations, setTbdMigrations] = useState<ReadonlyArray<Migration>>([]);
  const [doneMigrations, setDoneMigrations] = useState<ReadonlyArray<Migration>>([]);
  const [apiCallCount, refreshMigrations] = useState<number>(0);
  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });

  const { accessToken } = useAuth();

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      setMigrations(await GetMigrations(accessToken));
    });
  }, [accessToken, apiCallCount]);

  useEffect(() => {
    setTbdMigrations(
      migrations
        .filter(m => m.category === 'Pending')
        .map((i, index) => {
          return { ...i, id: index };
        })
    );
    const allDoneMigrations = [
      ...orderBy(
        migrations.filter(m => m.category !== 'Pending'),
        ['installedOn'],
        ['desc']
      ),
    ];

    const filtered: Migration[] = [];
    const seenVersions: { [ver: string]: boolean } = {};

    for (const m of allDoneMigrations) {
      if (!(m.version in seenVersions)) {
        seenVersions[m.version] = true;
        filtered.push(m);
      }
    }

    setDoneMigrations(
      filtered.map((i, index) => {
        return { ...i, id: index };
      })
    );
  }, [migrations]);

  function repairMigrations() {
    setLoadingState({ status: 'Loading' });
    ErrorManagement('Loading', setLoadingState, async () => {
      setLoadingState({ status: 'Loading' });
      await RepairMigrations(accessToken);

      refreshMigrations(apiCallCount + 1);
    });
  }

  function runMigrations() {
    ErrorManagement('Loading', setLoadingState, async () => {
      setLoadingState({ status: 'Loading' });
      await ExecuteMigrations(accessToken);

      refreshMigrations(apiCallCount + 1);
    });
  }

  return (
    <AuthorizedPage>
      <Page title={t('superDuperFiestaMigrations')}>
        <PageContent>
          <Box sx={{ height: '100%', overflow: 'hidden' }} mb={2}>
            <FlexBox sx={{ flexDirection: 'row', gap: 3, height: '100%' }}>
              <FlexBox sx={{ flexDirection: 'column', gap: 2, width: '40%' }}>
                <Typography variant={'h3'}>{t('pending')}</Typography>
                <FlexTableBox>
                  <Table
                    loadingState={loadingState}
                    migrations={tbdMigrations}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          installedOn: false,
                        },
                      },
                    }}
                  />
                </FlexTableBox>
                <div style={{ paddingTop: '5px' }}>
                  <AuthorizedSection hasAdminAccess>
                    <button style={{ backgroundColor: 'lightgreen' }} onClick={runMigrations}>
                      {t('applyMigrations')}
                    </button>
                    <span style={{ paddingRight: '50px' }}></span>
                    <button style={{ backgroundColor: 'pink' }} onClick={repairMigrations}>
                      {t('repairMigrations')}
                    </button>
                  </AuthorizedSection>
                </div>
              </FlexBox>

              <Divider orientation={'vertical'} />

              <FlexBox sx={{ flexDirection: 'column', gap: 2, width: '60%' }}>
                <Typography variant={'h3'}>{t('completed')}</Typography>
                <Table
                  loadingState={loadingState}
                  migrations={doneMigrations}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: installedOn, sort: 'desc' }],
                    },
                  }}
                />
              </FlexBox>
            </FlexBox>
          </Box>
        </PageContent>
      </Page>
    </AuthorizedPage>
  );
};

const Table = ({
  migrations,
  initialState,
  loadingState,
}: {
  migrations: ReadonlyArray<Migration>;
  initialState?: GridInitialStatePro;
  loadingState: LoadingState;
}) => {
  const apiRef = useGridApiRef();
  const columns = useColumns();

  return (
    <CompactGridWrapper
      apiRef={apiRef}
      loadingState={loadingState}
      columns={columns}
      rows={migrations}
      enableCustomAutoColumnResizing={true}
      disableRowSelectionOnClick={true}
      initialState={initialState}
    />
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(
    () => [
      {
        field: version,
        headerName: t(version),
        align: 'left',
        maxWidth: 120,
      },
      {
        field: description,
        headerName: t(description),
        align: 'left',
      },
      {
        field: installedOn,
        headerName: t(installedOn),
        align: 'left',
        type: 'date',
        valueGetter: dateGetter,
      },
    ],
    [t]
  );
};
