import React, { useEffect, useState } from 'react';

import { Container, Stack, Typography, useTheme } from '@mui/material';
import {
  GridColDef,
  GridNoRowsOverlay,
  GridRowSelectionModel,
  GridValueGetterParams,
  NoRowsOverlayPropsOverrides,
} from '@mui/x-data-grid-pro';
import NavigatableDataGrid from '../../grids/NavigatableDataGrid';
import {
  useMeasurementDefinitions,
  usePatient,
  useResearchProjectPatients,
  useSelectPatient,
} from '../../../stores/dataStore';
import { useGlobalDateRange } from '../../../stores/dateRangeStore';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { REVIEW_STATUSES, ReviewStatus } from '../../../../../data/PatientReviewData';
import { PatientApprovalPatient } from '../../../../../data/PatientApprovalData';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { ErrorTwoTone } from '@mui/icons-material';

type PatientRow = PatientApprovalPatient & {
  deidentity: string;
  reviewStatusName: ReviewStatus['displayName'] | null;
};

const PatientList: React.FC<{
  selectNextPatientRef: React.MutableRefObject<() => void>;
  selectPrevPatientRef: React.MutableRefObject<() => void>;
}> = ({ selectNextPatientRef, selectPrevPatientRef }) => {
  const { loading, data: patients, error: patientsError } = useResearchProjectPatients();
  const globalDateRange = useGlobalDateRange();
  const { data: measurementDefinitions } = useMeasurementDefinitions();
  const currentPatient = usePatient();
  const selectPatient = useSelectPatient();

  const [patientRows, setPatientRows] = useState<PatientRow[]>([]);

  // Rebuild the rows with the new counts in the date range whenever the patient data or date range changes.
  useEffect(() => {
    if (!patients) {
      setPatientRows([]);
      return;
    }

    const newRows: PatientRow[] = patients.map(p => ({
      ...p,
      deidentity: `${p.token1}:${p.token2}`,
      reviewStatusName:
        (p.reviewStatusId && REVIEW_STATUSES.find(rs => rs.reviewStatusId === p.reviewStatusId)?.displayName) ?? null,
    }));

    setPatientRows(newRows);
  }, [patients, globalDateRange]);

  type DirectCol = Exclude<GridColDef, 'renderCell'> & { field: keyof PatientRow };
  type ComputedCol = GridColDef & { field: string } & (
      | { valueGetter: (params: GridValueGetterParams<PatientRow>) => string }
      | { renderCell: (params: GridRenderCellParams<PatientRow>) => string }
    );
  type PatientGridColDef = DirectCol | ComputedCol;

  const [columns, setColumns] = useState<PatientGridColDef[]>([]);
  useEffect(() => {
    const statisticMeasurements = measurementDefinitions?.filter(m => m.isTargetStatistic);
    if (statisticMeasurements && statisticMeasurements.length > 1) {
      throw new Error('Found more than one statistic measurement');
    }

    const statisticMeasurement = statisticMeasurements?.[0];
    const statisticColumnName = statisticMeasurement ? `${statisticMeasurement.shortName} count` : 'Statistic';

    setColumns([
      { field: 'deidentity', headerName: 'Deidentity', width: 150, editable: false, type: 'string' },
      {
        field: 'yearOfBirth',
        headerName: 'YOB',
        width: 75,
        editable: false,
        type: 'number',
        renderCell: (params: GridRenderCellParams<PatientRow>) => params.row.yearOfBirth?.toString(),
      },
      { field: 'state', headerName: 'State', width: 75, editable: false, type: 'string' },

      {
        field: 'sampleBiobanks',
        headerName: 'Biobanks',
        description: "Biobanks for patient's selected samples",
        width: 150,
        editable: false,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<PatientRow>) => params.row.sampleBiobanks.join(', '),
      },

      { field: 'statisticTotalCount', headerName: statisticColumnName, width: 100, editable: false, type: 'number' },

      {
        field: 'labCount',
        headerName: 'Lab count',
        description: 'Curated and (total) lab count without issues',
        width: 120,
        editable: false,
        type: 'number',
        valueGetter: (params: GridValueGetterParams<PatientRow>) => params.row.labTotalWithoutIssuesCount,
        renderCell: (params: GridRenderCellParams<PatientRow>) =>
          `${params.row.labCuratedTotalWithoutIssuesCount.toLocaleString()} (${params.row.labTotalWithoutIssuesCount.toLocaleString()})`,
      },

      {
        field: 'obsCount',
        headerName: 'Obs. count',
        description: 'Curated and (total) observation count without issues',
        width: 120,
        editable: false,
        type: 'number',
        valueGetter: (params: GridValueGetterParams<PatientRow>) => params.row.obsTotalWithoutIssuesCount,
        renderCell: (params: GridRenderCellParams<PatientRow>) =>
          `${params.row.obsCuratedTotalWithoutIssuesCount.toLocaleString()} (${params.row.obsTotalWithoutIssuesCount.toLocaleString()})`,
      },

      {
        field: 'medTotalWithoutIssuesCount',
        headerName: 'Med. count',
        description: 'Total medication count without issues',
        width: 120,
        editable: false,
        type: 'number',
      },

      {
        field: 'procTotalWithoutIssuesCount',
        headerName: 'Proc. count',
        description: 'Total procedure count without issues',
        width: 120,
        editable: false,
        type: 'number',
      },

      {
        field: 'problemTotalWithoutIssuesCount',
        headerName: 'Prob. count',
        description: 'Total problem count without issues',
        width: 120,
        editable: false,
        type: 'number',
      },

      {
        field: 'totalWithIssuesCount',
        headerName: 'Issues Count',
        description: 'Curated and (total) with issues count across all types',
        width: 120,
        editable: false,
        type: 'number',
        valueGetter: (params: GridValueGetterParams<PatientRow>) =>
          params.row.labTotalWithIssuesCount +
          params.row.obsTotalWithIssuesCount +
          params.row.medTotalWithIssuesCount +
          params.row.procTotalWithIssuesCount,
        renderCell: (params: GridRenderCellParams<PatientRow>) => {
          const totalWithIssues =
            params.row.labTotalWithIssuesCount +
            params.row.obsTotalWithIssuesCount +
            params.row.medTotalWithIssuesCount +
            params.row.procTotalWithIssuesCount;
          const totalCuratedWithIssues =
            params.row.labCuratedTotalWithIssuesCount + params.row.obsCuratedTotalWithIssuesCount;
          return `${totalCuratedWithIssues.toLocaleString()} (${totalWithIssues.toLocaleString()})`;
        },
      },

      { field: 'reviewStatusName', headerName: 'Status', width: 100, editable: false, type: 'string' },
      { field: 'reviewScore', headerName: 'Score', width: 75, editable: false, type: 'number' },
      { field: 'reviewNotes', headerName: 'Notes', width: 150, editable: false, type: 'string' },
    ]);
  }, [measurementDefinitions]);

  const onSelectionModelChange = (selectionModel: GridRowSelectionModel) => {
    if (selectionModel.length === 0) {
      return;
    }

    const [token1, token2] = (selectionModel[0] as string).split(':');
    const patient = patients?.find(p => p.token1 === token1 && p.token2 === token2);
    if (patient && patient !== currentPatient) {
      selectPatient(patient);
    }
  };

  return (
    <Container maxWidth={false} disableGutters={true} sx={{ height: '100%' }}>
      <NavigatableDataGrid
        selectNextRowRef={selectNextPatientRef}
        selectPrevRowRef={selectPrevPatientRef}
        getRowId={(patient: PatientRow) => patient.deidentity}
        onRowSelectionModelChange={onSelectionModelChange}
        density='compact'
        loading={loading}
        rowSelection={true}
        rows={patientRows}
        columns={columns}
        hideFooter={false}
        autoPageSize={true}
        pagination={true}
        sx={{ cursor: 'pointer', maxWidth: '100%' }}
        rowHeight={25}
        slotProps={{
          noRowsOverlay: { error: patientsError } as NoRowsOverlayPropsOverrides,
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Container>
  );
};

const CustomNoRowsOverlay: React.FC<GridProSlotProps['noRowsOverlay'] & { error: string | undefined }> = ({
  error,
}) => {
  const theme = useTheme();

  return error === undefined ? (
    <GridNoRowsOverlay />
  ) : (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100%', padding: theme.spacing(2) }}
    >
      <ErrorTwoTone color='error' fontSize='large' />
      <Typography color={theme.palette.error.main} textAlign='center'>
        {error}
      </Typography>
    </Stack>
  );
};

export default PatientList;
