import { LabAssignedSampleLabel } from './SampleTrackingData';

export interface Sample {
  sampleId: string;
  patientId: string;
  labId: string;
  foreignHash?: string;
  sampleBbid?: string;
  volume?: number;
  collectionDate: Date;
  biobankId?: string;
  createdAt: Date;
  updatedAt: Date;
  sampleTypeId: string;
  biobankDecision: string;
  sampleAvailabilityTypeId: string;
}

export function getSampleIdentifier(sample: Sample, labAssignedSampleLabel?: LabAssignedSampleLabel) {
  return getTransitionSampleIdentifier(labAssignedSampleLabel as any, sample);
}

export const getTransitionSampleIdentifier = (labAssignedSampleLabel?: LabAssignedSampleLabel, sample?: Sample) =>
  labAssignedSampleLabel?.label ??
  sample?.sampleBbid ??
  truncateForeignHash(sample?.foreignHash ?? '') ??
  sample?.sampleId;

export function truncateGuid(guid: string) {
  return guid ? guid.substring(23) : '';
}

export function truncateNoDashGuid(guid: string) {
  return guid ? guid.substring(24) : '';
}

export function truncateForeignHash(hash: string) {
  if (hash?.length === 0) {
    return '';
  }

  if (hash !== undefined && hash.length > 300) {
    return hash ? hash.substring(0, 23) + '...' : '';
  }

  return hash;
}
