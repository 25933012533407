// Contains functions for transforming server data into data suitable for client visualizations. These helper functions
// generally include date math/manipulation and are consolidated here to keep those calculations close together, both
// for ease of maintenance and to ensure that all visualizations are using the same logic for date manipulation.

import { DateRange } from '../models/DateRange';
import { eachWeekOfInterval, Interval, isWithinInterval } from 'date-fns';
import { fromUtc, toExclusiveInterval, toUtcInterval } from '../utils/dateUtils';
import {
  PatientApprovalMeasurementDefinitionType,
  PatientApprovalPatientWeeklyCount,
  PatientApprovalPatientMeasurement,
} from '../../../data/PatientApprovalData';

// Filters down the given measurements to only those that fall within the given date range.
export const getMeasurementsInRange = (
  measurements: PatientApprovalPatientMeasurement[],
  dateRange: DateRange
): PatientApprovalPatientMeasurement[] => {
  return measurements.filter(m => isWithinInterval(new Date(m.performedWeek), toExclusiveInterval(dateRange)));
};

// Fills in any gaps in the weekly counts for the given type within the given date range while omitting any counts that
// fall outside the range. The result will be that every week in the date range will have an output object that contains
// either the sum of input counts in that week or zero if there weren't any. The input weekly counts must all be of the
// same type.
export const fillWeeklyCountGaps = (
  weeklyCounts: PatientApprovalPatientWeeklyCount[],
  type: PatientApprovalMeasurementDefinitionType,
  dateRange: DateRange
): PatientApprovalPatientWeeklyCount[] => {
  // We don't care about this check being in UTC because an interval check is not affected by the timezone.
  const countsInRange = weeklyCounts.filter(count =>
    isWithinInterval(new Date(count.performedWeek), toExclusiveInterval(dateRange))
  );

  const res: PatientApprovalPatientWeeklyCount[] = [];

  let prevDate = dateRange.earliest;
  let curIdx = 0;

  // Iterate over every week in the date range to produce one value per week. We generate the intervals in UTC to ensure
  // that the proper week starts are used.
  for (const curDate of eachWeekOfInterval(toUtcInterval(dateRange)).map(fromUtc)) {
    const weekInterval: Interval = toExclusiveInterval({ earliest: prevDate, latest: curDate });

    // Find all the counts that are in the current week. This relies on the counts being sorted by performedWeek.
    const inCurrentWeek: PatientApprovalPatientWeeklyCount[] = [];
    while (
      curIdx < countsInRange.length &&
      isWithinInterval(new Date(countsInRange[curIdx].performedWeek), weekInterval)
    ) {
      inCurrentWeek.push(countsInRange[curIdx]);
      curIdx++;
    }

    // If we had any counts in the current week then aggregate them, otherwise insert a zero count.
    if (inCurrentWeek.length > 0) {
      const totalCount = inCurrentWeek.reduce((acc, cur) => acc + cur.totalCount, 0);
      const noIssuesCount = inCurrentWeek.reduce((acc, cur) => acc + cur.noIssuesCount, 0);
      res.push({
        ...inCurrentWeek[0],
        performedWeek: prevDate.toISOString(),
        totalCount: totalCount,
        noIssuesCount: noIssuesCount,
      });
    } else {
      res.push({ performedWeek: prevDate.toISOString(), totalCount: 0, noIssuesCount: 0, type });
    }

    prevDate = curDate;
  }

  return res;
};
